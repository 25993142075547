@import "https://fonts.googleapis.com/css?family=Barlow:200,300,400,500,600,700,800";

body {
  background: #fff;
  color: #97999b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Barlow', sans-serif !important;
  font-size: 16px;
  line-height: 30px
}

p {
  font-family: 'Barlow', sans-serif;
  font-size: 16px;
  line-height: 30px
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333f48;
  font-family: 'Barlow', sans-serif;
  font-weight: 700
}

h1 {
  font-size: 48px;
  line-height: 48px
}

h2 {
  font-size: 36px;
  line-height: 36px
}

h3 {
  font-size: 22px;
  margin-bottom: 20px
}

h4 {
  font-size: 18px;
  line-height: 28px
}

h5 {
  font-size: 14px;
  line-height: 24px
}

.d-none-estados {
  display: none;
}

.font-size-13 {
  font-size: 13px !important
}

.btn-fondo {
  color: #8d8f91 !important;
  font-weight: 800;
}

.btn-fondo:hover {
  color: #fa4616 !important
}

.color-white {
  color: white !important
}

.color-nevasa {
  color: #fa4616 !important
}

.btn-nevasa {
  background-color: #fa4616 !important;
  color: white;
}

.btn-nevasa:hover {
  background-color: #fa4616 !important;
  color: white;
}

.btn-nevasa.desactivado {
  background-color: #8d8f91 !important;
  color: white;
}

.gsc-search-button-v2 {
  padding: 0 20px !important
}

.gsc-search-button-v2 svg {
  margin-top: 7px !important
}

.btn-inicio {
  padding: 10px !important;
  font-size: 15px !important;
  border-radius: 5px !important
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #333f48;
  border-color: #333f48
}

.fichas-enrolamiento {
  font-size: 25px !important;
  text-transform: uppercase;
  padding-left: 10px !important;
  line-height: 30px !important
}

html {
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  position: relative;
  text-rendering: optimizeLegibility
}

body {
  border: 0;
  margin: 0;
  padding: 0
}

.body-inner {
  position: relative;
  overflow: hidden
}

.display-contents {
  display: contents
}

a {
  color: #fa4616
}

a:link,
a:visited {
  text-decoration: none
}

a:hover {
  text-decoration: none;
  color: #fa4616
}

.link-more {
  font-weight: 700;
  position: relative;
  z-index: 1999
}

.link-more i {
  font-weight: 700;
  top: 2px;
  margin-left: 5px;
  position: relative;
  font-size: 16px
}

.link-more.color {
  color: #fa4616
}

section,
.section-padding {
  padding: 60px 0;
  position: relative;
  z-index: 3
}

section.map-section {
  padding: 0
}

.no-padding {
  padding: 0 !important
}

.padd-50 {
  padding: 0 0 150px
}

.no-border {
  border: none !important
}

.ts-padding {
  padding: 70px 50px
}

.padding-box {
  padding: 40px
}

.media>.pull-left {
  margin-right: 20px
}

.gap-100 {
  clear: both;
  height: 100px
}

.gap-80 {
  clear: both;
  height: 80px
}

.gap-60 {
  clear: both;
  height: 60px
}

.gap-40 {
  clear: both;
  height: 40px
}

.gap-30 {
  clear: both;
  height: 30px
}

.gap-20 {
  clear: both;
  height: 20px
}

.gap-15 {
  clear: both;
  height: 15px
}

.mrtb-40 {
  margin-top: 40px;
  margin-bottom: 40px
}

@media screen and (min-width: 992px) {
  .mrt-225 {
    margin-top: 225px
  }

  .mrt-190 {
    margin-top: 190px
  }

  .mrt-140 {
    margin-top: 140px
  }

  .mrt-110 {
    margin-top: 110px
  }
}

.mrt-60 {
  margin-top: 60px
}

.mrt-40 {
  margin-top: 40px
}

.mrt-85 {
  margin-top: 85px
}

.mrt-20 {
  margin-top: 20px
}

.mrb-20 {
  margin-bottom: 20px
}

.mrb-40 {
  margin-bottom: 40px
}

.mrb-60 {
  margin-bottom: 60px
}

.mr-0 {
  margin: 0 !important
}

.mrt-0 {
  margin-top: 0 !important;
  margin-bottom: 20px !important
}

.patb-100 {
  padding: 100px 0 !important
}

.pab-120 {
  padding-bottom: 120px !important
}

.pab {
  padding-bottom: 0
}

a:focus {
  outline: 0
}

img.pull-left {
  margin-right: 20px;
  margin-bottom: 20px
}

img.pull-right {
  margin-left: 20px;
  margin-bottom: 20px
}

.unstyled,
ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0
}

.solid-bg {
  background: #f7f9fd;
  -webkit-box-shadow: none;
  box-shadow: none
}

.color-bg {
  background: #fa4616
}

.dark-bg {
  background: #252525;
  color: #fff
}

.grey-light-bg {
  background: #f7f9fd
}

.grey-bg {
  background: #f1f1f1
}

.dark-bg h2,
.dark-bg h3 {
  color: #fff
}

.image-bg {
  position: relative
}

/* .image-bg:before{content:'';width:100%;height:100%;top:0;left:0;position:absolute;background:linear-gradient(to top,rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.9) 100%),url(../images/slider/bg7.jpg);background-size:cover;background-position:center;background-attachment:fixed} */
.image-bg2 {
  position: relative
}

/* .image-bg2:before{content:'';width:100%;height:100%;top:0;left:0;position:absolute;background:linear-gradient(to top,rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.7) 100%),url(../images/slider/bg7.jpg);background-size:cover;background-position:center;background-attachment:fixed} */
.hero-area {
  height: 100vh;
  background-size: cover;
  background-position: center
}

/* .hero-bg{background-image:url(../images/slider/bg3.jpg)} */
.hero-content-wrapper {
  height: 100%;
  display: table;
  width: 100%;
  position: relative;
  z-index: 2
}

.hero-content-inner {
  display: table-cell;
  vertical-align: middle
}

.hero-title h1 {
  color: #fff;
  font-size: 100px;
  font-family: 'Barlow', sans-serif;
  line-height: 115px
}

.hero-title p {
  color: #fff;
  font-size: 46px;
  margin-top: 25px;
  font-family: 'Barlow', sans-serif
}

.hero-title-bg {
  position: relative;
  z-index: 2
}

.scroll-down {
  position: absolute;
  left: 50%;
  bottom: 60px;
  margin-left: -25px;
  display: block
}

.scroll-down a {
  font-size: 50px;
  color: #fff
}

.scroll-down i {
  transform: rotate(270deg);
  display: inline-block
}

.solid-row {
  background: #f0f0f0
}

.pattern-bg {
  position: relative
}

/* .pattern-bg:before{content:'';width:100%;height:100%;top:0;left:0;position:absolute;background:url(../images/pattern/bg_pattern1.png)} */
.media-left,
.media>.pull-left {
  padding-right: 20px
}

.bg-overlay {
  position: relative
}

.bg-overlay .row {
  position: relative;
  z-index: 199
}

.bg-overlay:after {
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 0;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.bg-overlay.overlay-color:after {
  background-color: rgba(51, 63, 72, 0.85)
}

.bg-overlay.overlay-color-2:after {
  background-color: rgba(51, 63, 72, 0.55)
}

.bg-overlay.overlay-color-3:after {
  background-color: rgba(0, 0, 0, 0.55)
}

.bg-pattern-1 {
  position: relative;
  z-index: 2
}

/* .bg-pattern-1:before{position:absolute;top:0;left:0;width:100%;height:100%;content:"";background:url(../images/pattern/bg_pattern1.png) 100% 100%;z-index:-1} */
@media screen and (max-width: 991px) {
  .bg-pattern-1:after {
    display: none
  }
}

.bg-pattern-2 {
  position: relative;
  z-index: 2
}

/* .bg-pattern-2:before{position:absolute;top:0;left:0;content:"";width:100%;height:100%;background:url(../images/pattern/pattern_arrow.png) no-repeat top left} */
/* .bg-pattern-2:after{background:url(../images/pattern/pattern_box.png) no-repeat bottom right;content:"";width:100%;height:100%;right:0;bottom:0;position:absolute;z-index:-1} */
#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1999;
  top: 0;
  left: 0
}

@media screen and (max-width: 767px) {

  .bg-pattern-2:before,
  .bg-pattern-2:after {
    display: none
  }
}

.bg-pattern-3 {
  position: relative;
  z-index: 2
}

/* .bg-pattern-3:after{position:absolute;top:0;left:0;width:100%;height:100%;content:"";background:url(../images/pattern/bg_pattern2.png);background-size:100%;background-position:center;z-index:-1} */
@media screen and (max-width: 991px) {
  .bg-pattern-3:after {
    display: none
  }
}

.dropcap {
  font-size: 48px;
  line-height: 60px;
  padding: 0 7px;
  float: left;
  font-weight: 700;
  margin: 5px 15px 5px 0;
  position: relative;
  text-transform: uppercase
}

.dropcap.primary {
  background: #fa4616;
  color: #fff
}

.dropcap.secondary {
  background: #3a5371;
  color: #fff
}

.ts-intro .border-left {
  border-left: 2px solid #dee2e6 !important
}

.box-primary {
  background-color: #fa4616
}

.box-dark {
  background-color: #222
}

.box-secondary {
  background-color: #97999b
}

.intro-details {
  padding-left: 20px
}

.intro-title {
  font-size: 24px;
  line-height: 34px;
  margin: 0
}

.intro-sub-title {
  font-weight: 900;
  font-size: 36px;
  line-height: 46px;
  margin: 15px 0 25px;
  color: #1c1c24
}

section .title-white {
  color: #fff !important
}

.section-title-vertical {
  position: relative;
  height: 0
}

.section-title-vertical .section-title {
  position: absolute;
  left: -190px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 203px;
  font-size: 40px;
  display: inline-block;
  margin-bottom: 70px;
  color: #c7d4f3;
  margin-top: 0
}

.section-title-vertical .section-title:after {
  top: 15px;
  left: -95px;
  z-index: 0;
  width: 155px;
  border-bottom: 2px solid #c7d4f3
}

.section-title-vertical.style-2 .section-title {
  left: auto;
  top: 300px;
  right: -180px;
  display: inline-block;
  -webkit-transform: rotate(+90deg);
  transform: rotate(+90deg)
}

@media screen and (max-width: 1024px) {
  .section-title-vertical .section-title {
    display: none
  }
}

.column-title {
  font-size: 44px;
  line-height: 50px;
  margin: 0 0 20px;
  position: relative
}

.column-title span {
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 10px;
  display: block;
  font-weight: 400;
  position: relative
}

.column-title.title-small {
  font-size: 36px;
  position: relative
}

.embed-responsive {
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 5px 20px;
  background: none;
  font-size: 14px;
  font-weight: 400
}

.form-control.color-1 {
  border: 1px solid #6387dd;
  color: #fff !important
}

.form-control.color-1::placeholder {
  color: #fff
}

.form-control.color-1:-ms-input-placeholder {
  color: #fff
}

.form-control.color-1::-ms-input-placeholder {
  color: #fff
}

.form-control.color-1:focus {
  background: #fa4616
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #fa4616
}

input:focus,
textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #F1F1F1
}

.form-message {
  height: auto
}

@media screen and (min-width: 992px) {
  .qutoe-form-inner-left {
    padding-right: 40px
  }
}

@media screen and (min-width: 992px) {
  .qutoe-form-inner-right {
    padding-left: 40px
  }
}

.quote_form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #fa4616
}

.widget .form-control {
  z-index: 0;
  position: relative
}

hr {
  background-color: #e7e7e7;
  border: 0;
  height: 1px;
  margin: 20px 0
}

blockquote {
  position: relative;
  color: #fff;
  background: #333f48;
  padding: 40px;
  border: 0;
  margin: 30px 0 40px
}

blockquote:before {
  padding: 28px 0 22px;
  content: "\e937";
  font-family: 'iconfont';
  color: #fff;
  font-size: 44px
}

blockquote p {
  font-size: 18px;
  line-height: 28px;
  position: relative;
  z-index: 1;
  font-style: italic;
  font-weight: 600
}

cite {
  display: block;
  font-size: 14px;
  margin-top: 10px;
  text-align: right
}

.btn.focus,
.btn:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none
}

.btn-primary.focus,
.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

button:focus {
  outline: 0 solid
}

.btn.btn-primary,
.btn-dark,
.btn-border,
.btn-white {
  font-weight: 600;
  padding: 15px 40px;
  line-height: 1;
  -webkit-transition: color 400ms, background-color 400ms, border-color 400ms;
  transition: color 400ms, background-color 400ms, border-color 400ms;
  transition: color 400ms, background-color 400ms, border-color 400ms;
  position: relative
}

.btn-white.btn-primary {
  background: #fff;
  color: #fa4616
}

.btn.btn-primary {
  background: #fa4616;
  border: 2px solid transparent
}

.btn.btn-dark {
  background: #242427;
  border: 2px solid transparent;
  line-height: 1;
}

.btn.btn-white {
  background: #fff;
  border: 2px solid transparent
}

.btn.btn-white:hover {
  background-color: #242427;
  color: #fff
}

.btn-border {
  border: 2px solid #fff;
  background: none;
  color: #fff;
  box-shadow: 0 15px 20px -5px rgba(14, 56, 133, 0.15) !important
}

.btn.padd-btn {
  padding: 15px 60px 15px 40px
}

.btn-primary:hover,
.btn-dark:hover,
.btn-white.btn-primary:hover {
  background: #97999b;
  color: #fff
}

.btn-border:hover {
  background: #97999b;
  border: 2px solid transparent;
  color: #fff
}

i.btn-arrow {
  position: absolute;
  right: 3px;
  top: 3px;
  background: #000;
  padding: 15px;
  border-radius: 100%
}

.general-btn {
  clear: both;
  margin-top: 60px
}

.intro-content .btn-border {
  margin-right: 15px
}

.list-round,
.list-arrow,
.list-dash {
  list-style: none;
  margin: 0;
  padding: 0
}

.list-round li {
  line-height: 34px
}

.list-round li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f111";
  margin-right: 10px;
  color: #333f48;
  font-size: 8px;
  position: relative;
  top: -3px;
  font-weight: 900
}

.list-arrow {
  padding: 0
}

ul.list-dash li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f068";
  margin-right: 10px;
  color: #fa4616;
  font-size: 10px;
  font-weight: 900
}

.nav-tabs>li.active>a {
  border: 0
}

.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  border: 0
}

.nav-tabs>li>a {
  border: 0
}

.nav-tabs>li>a:hover {
  border: 0
}

.nav>li>a:focus,
.nav>li>a:hover {
  background: none
}

.pattern-bg {
  background: #f5f5f5
}

a[href^=tel] {
  color: inherit;
  text-decoration: none
}

.owl-carousel.page-slider .owl-nav {
  margin: 0
}

.owl-carousel .owl-nav {
  text-align: center
}

.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  background: #fa4616;
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 42px;
  font-size: 20px;
  text-align: center;
  margin: 0;
  border-radius: 0;
  -webkit-transition: 400ms;
  transition: 400ms;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.owl-carousel.owl-theme .owl-nav [class*=owl-prev] {
  right: 40px
}

.owl-carousel.owl-theme .owl-nav>div:hover {
  background: #272d33
}

.owl-theme .owl-dots {
  text-align: center;
  margin-top: 40px;
  position: relative;
  z-index: 1
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block
}

.owl-theme .owl-dots .owl-dot span {
  width: 30px;
  height: 4px;
  margin: 5px 4px;
  background: #000;
  opacity: .2;
  display: inline-block
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #fa4616;
  opacity: 1
}

.section-title {
  font-family: 'Barlow', sans-serif;
  font-size: 35px;
  margin-top: 0;
  display: inline-block;
  margin-bottom: 90px;
  position: relative
}

.section-title span {
  font-size: 18px;
  margin-bottom: 15px;
  color: #97999b;
  text-align: center;
  display: block;
  font-weight: 400 !important;
  line-height: normal
}

.section-title:after {
  content: '';
  position: absolute;
  bottom: -30px;
  height: 1px;
  width: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 0;
  border-bottom: 5px solid #fa4616
}

.section-title.ft-size {
  font-size: 24px;
  font-weight: 500
}

.content-title {
  font-size: 40px;
  margin-top: 0;
  border-left: 5px solid #fa4616;
  padding-left: 15px;
  margin-bottom: 40px;
  line-height: 45px
}

.content-title span {
  display: block;
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 10px;
  display: block;
  font-weight: 400 !important
}

.content-title-medium {
  font-size: 36px;
  line-height: 44px
}

.list-column-title {
  font-size: 24px;
  color: #333f48
}

.section-title.border-title-left {
  padding-left: 140px
}

.section-title.border-title-left:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -22px;
  height: 1px;
  width: 115px;
  left: 0;
  z-index: 0;
  border-bottom: 5px solid #fa4616
}

.section-title.border-title-left:after {
  border: 0
}

.section-title.border-title-left span {
  text-align: right;
  margin: 10px 0 0
}

.site-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999
}

@media screen and (min-width: 992px) {
  .site-top-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999
  }
}

.top-bar {
  padding: 12px 0 15px;
  position: relative;
  background: #2d3559;
  color: #fff;
  line-height: normal
}

.top-bar.solid-bg {
  color: #97999b;
  background: #fafafa
}

.top-bar.border-bottom {
  border-bottom: 1px solid #f1f1f1
}

.top-bar.solid-bg ul.top-menu li {
  border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.top-bar.solid-bg ul.top-menu li:last-child {
  border-right: 0
}

.top-bar.solid-bg ul.top-menu li a {
  color: #97999b
}

.top-bar.solid-bg .top-social a {
  color: #97999b
}

.top-bar.solid-bg ul.top-menu li a:hover,
.top-bar.solid-bg .top-social a:hover {
  color: #fa4616
}

ul.top-menu li {
  display: inline-block;
  font-size: 14px;
  border-right: 1px solid #4c5372;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 10px
}

ul.top-menu li:last-child {
  border-right: 0
}

ul.top-menu li a {
  color: #999
}

ul.top-menu li a:hover {
  color: #fa4616
}

.top-social {
  list-style: none;
  margin: 0;
  padding: 0
}

.top-social a {
  font-size: 14px;
  margin-left: 20px;
  color: #fff
}

.top-social a:hover {
  color: #fa4616
}

.top-social.color-1 a {
  font-size: 16px;
  color: #fa4616
}

.top-social.color-1 a:hover {
  color: #fa4616
}

.topbar-transparent {
  color: #fff;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 12px 0;
  line-height: normal;
  -webkit-transition: 350ms;
  transition: 350ms
}

.topbar-transparent ul li a {
  color: #fff;
  font-weight: 600
}

.topbar-transparent.border-down {
  line-height: normal
}

.top-bar.highlight {
  background: #97999b;
  color: #fff
}

.top-bar.highlight ul.top-menu li {
  border-right: 1px solid rgba(0, 0, 0, 0.2)
}

.top-bar.highlight ul.top-menu li a {
  color: #fff;
  font-weight: 600
}

.top-bar.highlight ul.top-menu li:last-child {
  border-right: 0
}

.topbar-transparent.with-bg {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 0 12px;
  line-height: normal
}

#top-bar .top-info {
  margin: 0;
  float: left
}

#top-bar .top-info p {
  color: #fff;
  font-weight: 400;
  font-size: 14px
}

#top-bar .top-info i {
  color: #fff;
  font-size: 15px
}

#top-bar .top-info .info-icon {
  top: 0;
  margin-right: 5px
}

#top-bar .top-info .info-wrapper:after {
  height: 15px;
  top: 2px;
  background: rgba(255, 255, 255, 0.2)
}

#top-bar .top-info li.last .info-wrapper:after {
  background: none
}

#top-bar .top-info.style-1 p {
  color: #222
}

#top-bar .top-info.style-1 i {
  color: #fa4616
}

.top-bar.white-bg {
  background: #fff;
  border-bottom: 1px solid #e4e4e4
}

#header.fixed,
.site-nav-inner.fixed,
#header.header-standard.fixed,
#header.header-standard.fixed.highlight,
.site-nav-inner.site-navigation.fixed,
.site-nav-inner.site-navigation.fixed.nav-transparent {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  -webkit-animation: menusticky .7s ease-in-out;
  animation: menusticky .7s ease-in-out
}

.header-trans-leftbox.fixed {
  background: rgba(51, 63, 72, 0.9)
}

#header.fixed.header-trans-leftbox .header-wrapper:before {
  background: none
}

.header-trans-leftbox.fixed .header-wrapper {
  background: none
}

#header.header-standard.header-transparent.fixed {
  background: rgba(51, 63, 72, 0.9)
}

#header.header-standard.fixed {
  background: #fff
}

#header.header-standard.fixed.highlight {
  background: #97999b
}

.site-nav-inner.site-navigation.fixed {
  background: #97999b
}

.site-nav-inner.site-navigation.nav-transparent.fixed {
  background: rgba(51, 63, 72, 0.9)
}

.header.nav-down.nav-box .site-nav-inner.fixed {
  background: #97999b
}

.header.nav-down.nav-boxed-transparent .site-nav-inner.fixed {
  background: #97999b
}

@-webkit-keyframes menusticky {
  0% {
    margin-top: -120px;
    opacity: 0
  }

  50% {
    margin-top: -64px;
    opacity: 0
  }

  100% {
    margin-top: 0;
    opacity: 1
  }
}

@keyframes menusticky {
  0% {
    margin-top: -120px;
    opacity: 0
  }

  50% {
    margin-top: -64px;
    opacity: 0
  }

  100% {
    margin-top: 0;
    opacity: 1
  }
}

.header-trans-leftbox.navbar-fixed {
  background: #000
}

.header-trans-leftbox.navbar-fixed .header-wrapper {
  bottom: 0;
  background: rgba(0, 0, 0, 0)
}

.header-trans-leftbox.navbar-fixed .header-trans-leftbox .header-wrapper:before {
  display: none
}

.header-trans-leftbox .header-wrapper {
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  position: relative
}

.header-trans-leftbox .header-wrapper:before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  display: block;
  width: 300%;
  height: 100%;
  right: -300%;
  top: 0
}

.header.header-box-left {
  height: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: absolute;
  width: 100%
}

.header-box-left .header-wrapper {
  bottom: -15px
}

.header-wrapper {
  background: #97999b;
  color: #fff;
  position: relative
}

.header-wrapper:before {
  content: "";
  position: absolute;
  background: #97999b;
  display: block;
  width: 300%;
  height: 100%;
  right: -300%;
  top: 0
}

.header-standard {
  background: #fff;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 2
}

.header-standard.header-transparent {
  top: 50px
}

.header-standard.header-transparent ul.navbar-nav>li>a {
  color: #fff
}

@media screen and (min-width: 767px) {
  .header-standard.header-transparent .logo {
    padding-left: 0
  }
}

.header-standard.header-transparent .navbar-nav .active>.nav-link {
  color: #fff
}

.header-standard.header-transparent.navbar-fixed {
  background: #000;
  top: 0
}

.header-standard .navbar-nav .active>.nav-link {
  color: #333f48
}

.header-standard ul.navbar-nav>li>a {
  color: #fff;
  line-height: 20px
}

@media screen and (min-width: 992px) {
  .header-standard ul.navbar-nav>li>a {
    color: #333f48
  }
}

.header-standard ul.navbar-nav>li:hover>a {
  color: #fa4616
}

.header-standard .container {
  position: relative
}

@media screen and (min-width: 992px) {
  .header-standard .site-nav-inner {
    padding-right: 50px
  }
}

@media screen and (max-width: 767px) {
  .header-standard .container {
    max-width: 100%;
    padding: 0
  }

  .header-standard .site-nav-inner {
    padding-right: 0
  }
}

.header-standard .nav-search {
  top: 22px;
  right: 15px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #fa4616;
  border-radius: 100%
}

.header-standard .nav-search span {
  top: 3px;
  position: relative
}

.header-standard .search-block .search-close {
  color: #fff;
  top: -68px;
  right: 0;
  background: #fa4616;
  opacity: 1;
  width: 45px;
  height: 45px;
  line-height: 35px;
  border-radius: 100%
}

.header-standard.header-boxed {
  background: none;
  position: absolute;
  -webkit-box-shadow: none;
  box-shadow: none;
  top: 50px;
  left: 0;
  right: 0
}

.header-standard.header-boxed .site-nav-inner {
  background: #fff;
  z-index: 1;
  margin: 0;
  padding: 0 20px
}

.header-standard.header-boxed .nav-search {
  right: 40px;
  background: #97999b
}

.header-standard.header-boxed .search-block .search-close {
  right: 25px;
  background: #97999b;
  top: -68px
}

.header-standard.header-boxed.highlight .row {
  background: #97999b
}

.header-standard.header-boxed.highlight ul.navbar-nav>li>a {
  color: #fff
}

.site-navigation.navdown {
  background: #97999b;
  position: relative
}

.site-navigation.navdown .navbar-collapse {
  padding-left: 0
}

.navbar-collapse {
  align-items: initial !important
}

.site-navigation.navdown ul.navbar-nav>li>a {
  line-height: 60px
}

.site-navigation.navigation.navdown {
  background: #97999b
}

.site-navigation .nav-search {
  top: 0;
  z-index: 2;
  width: 70px;
  height: 70px;
  line-height: 70px;
  right: 15px;
  background: rgba(0, 0, 0, 0.2)
}

.site-navigation .search-block .search-close {
  top: -70px;
  z-index: 2;
  width: 70px;
  height: 70px;
  line-height: 50px;
  right: 0;
  background: #003
}

header.header-standard.header-light .navbar-nav .active>.nav-link,
header.header-standard.header-light .navbar-nav .nav-link:focus {
  color: #fff
}

@media screen and (min-width: 992px) {

  header.header-standard.header-light .navbar-nav .active>.nav-link,
  header.header-standard.header-light .navbar-nav .nav-link:focus {
    color: #333f48
  }
}

header.header-standard.header-light .navbar-nav .active>.nav-link:hover,
header.header-standard.header-light .navbar-nav .nav-link:focus:hover {
  color: #F2672A
}

.header-standard .navbar-nav .nav-link:focus {
  color: #333f48;
  -webkit-transition: all .3s;
  transition: all .3s
}

.header-standard.header-boxed.highlight .navbar-nav .nav-link:focus {
  color: #fff;
  -webkit-transition: all .3s;
  transition: all .3s
}

.header-standard.header-boxed.highlight .site-nav-inner {
  background: #97999b
}

.header-standard.header-boxed.highlight .nav-search {
  background: #fa4616
}

.header-standard.header-boxed.highlight .search-block .search-close {
  background: #fa4616
}

.header-standard.header-boxed .navbar-nav .nav-link:focus {
  color: #333f48;
  -webkit-transition: all .3s;
  transition: all .3s
}

.header.nav-down.nav-box {
  height: 145px
}

.header.nav-down.nav-box .site-nav-inner .container {
  position: relative
}

.header.nav-down.nav-box .site-nav-inner .search-block .search-close {
  top: -60px;
  right: 20px;
  line-height: 30px
}

.header.nav-down.nav-box .site-navigation {
  background: none
}

.header.nav-down.nav-box .site-navigation.navdown.navbar {
  position: relative;
  background: #97999b;
  bottom: 0;
  z-index: 9;
  margin: 0;
  padding: 0 15px
}

.header.nav-down.nav-box .nav-search {
  right: 15px;
  top: 0;
  z-index: 9;
  width: 70px;
  height: 70px;
  line-height: 70px;
  background: rgba(0, 0, 0, 0.2)
}

.header.nav-down.nav-boxed-transparent {
  color: #fff;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 40px;
  z-index: 2;
  height: auto;
  -webkit-transition: 350ms;
  transition: 350ms;
  transition: 350ms
}

.header.nav-down.nav-boxed-transparent .site-navigation.navdown {
  background: none
}

.header.nav-down.nav-boxed-transparent .site-navigation.navdown .row {
  position: relative;
  background: #97999b;
  bottom: 0;
  z-index: 1;
  margin: 0
}

.header.nav-down.nav-boxed-transparent p,
.header.nav-down.nav-boxed-transparent i {
  color: #fff !important
}

.header.header-solid {
  background: #97999b;
  color: #fff
}

.header.header-solid .top-info li .info-wrapper p,
.header.header-solid .top-info .info-icon {
  color: #fff
}

.header.header-solid .top-info li .info-wrapper:after {
  background: rgba(255, 255, 255, 0.1)
}

.header.header-solid .top-info li.last .info-wrapper:after {
  background: none
}

@media screen and (max-width: 992px) {
  .top-info-wrraper {
    display: none
  }
}

.site-nav-inner.navigation-inner .container {
  position: relative
}

.site-nav-inner.navigation-inner nav.navbar.site-navigation.navdown {
  background: #97999b;
  padding: 0 15px
}

.site-nav-inner.navigation-inner .nav-search {
  top: 0;
  right: 15px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  background: rgba(0, 0, 0, 0.2)
}

.site-nav-inner.navigation-inner .search-block .search-close {
  top: -60px;
  right: 20px;
  line-height: 30px
}

.site-navigation.navdown.nav-transparent {
  background: transparent;
  position: absolute;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  left: 0;
  right: 0;
  z-index: 1
}

.header-standard.header-boxed.bg-transparent {
  background: transparent;
  position: absolute
}

.header-trans-leftbox,
.header-transparent {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 350ms;
  transition: 350ms
}

.header {
  background: #fff;
  z-index: 2
}

.navbar-fixed {
  z-index: 99999;
  position: fixed;
  opacity: .98;
  width: 100%;
  top: 0;
  -webkit-animation: fadeInDown 800ms;
  animation: fadeInDown 800ms;
  animation: fadeInDown 800ms
}

.header-trans-leftbox .logo {
  padding-left: 30px
}

.logo {
  height: 90px;
  display: table
}

.logo a {
  display: table-cell;
  vertical-align: middle
}

.logo-area {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.header.nav-down .logo {
  height: 110px
}

.header-right {
  float: right
}

.top-info {
  list-style: none;
  margin: 35px 0 0;
  padding: 0;
  float: right
}

.top-info li {
  display: inline-block
}

.top-info li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0
}

.top-info .info-icon {
  font-size: 20px;
  color: #fa4616;
  display: inline-block;
  top: -15px;
  position: relative;
  margin-right: 10px
}

.top-info .info-wrapper {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  padding-right: 10px
}

.top-info .info-wrapper:after {
  position: absolute;
  content: '';
  background: #ddd;
  right: -15px;
  width: 1px;
  height: 35px;
  top: 4px
}

.top-info li .info-wrapper p {
  font-size: 16px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 700;
  color: #333f48
}

.top-info li.last .info-wrapper:after {
  background: none
}

.top-info li .info-wrapper p.info-subtitle {
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
  margin-top: 5px
}

.navbar-expand-lg .navbar-nav .dropdown-submenu .nav-link {
  padding-left: 0
}

.navbar {
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  padding: 0;
  align-items: initial !important;
}

.navbar-brand {
  padding: 0
}

.navbar-toggle {
  float: right;
  margin-top: 20px;
  padding: 10px;
  border-radius: 3px;
  margin-right: 15px;
  background: #fa4616
}

.nav-item:hover>.dropdown-menu,
.dropdown-submenu:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-animation-name: fadeInUp-Menu;
  animation-name: fadeInUp-Menu
}

.dropdown-menu {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes fadeInUp-Menu {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes fadeInUp-Menu {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }
}

.nav-item.dropdown-submenu:hover>a {
  color: #fa4616
}

.navbar-nav>.nav-item:hover>.nav-link {
  color: #fa4616
}

.navbar-toggle .icon-bar {
  background: #fff;
  width: 20px
}

.navbar-toggle .i-bar {
  background: #fff
}

ul.navbar-nav {
  float: left
}

ul.navbar-nav>li {
  float: none;
  display: inline-block;
  position: relative;
  padding-right: 10px
}

ul.navbar-nav>li:last-child {
  padding-right: 0
}

ul.navbar-nav>li:last-child:after {
  background: none
}

ul.navbar-nav>li>a {
  color: #fff;
  text-rendering: optimizeLegibility;
  display: inline-block;
  padding: 5px 15px;
  line-height: 35px;
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  -webkit-transition: 350ms;
  transition: 350ms;
  transition: 350ms
}

ul.navbar-nav>li:hover>a:before,
ul.navbar-nav>li.active>a:before {
  border-color: #fa4616;
  opacity: 1;
  -webkit-transform: scale(1) translateZ(0);
  transform: scale(1) translateZ(0)
}

ul.navbar-nav>li>a:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  border-bottom: 3px solid transparent;
  -webkit-transform: scale(0) translateZ(0);
  transform: scale(0) translateZ(0);
  -webkit-transition: opacity .55s ease, -webkit-transform .45s ease;
  transition: opacity .55s ease, -webkit-transform .45s ease;
  transition: transform .45s ease, opacity .55s ease;
  transition: transform .45s ease, opacity .55s ease, -webkit-transform .45s ease;
  transition: transform .45s ease, opacity .55s ease
}

ul.navbar-nav>li>a:hover,
ul.navbar-nav>li>a:focus {
  background: none
}

ul.navbar-nav>li>a i {
  font-weight: 700
}

@media screen and (min-width: 992px) {
  ul.navbar-nav>li>a i {
    padding-left: 5px
  }
}

@media screen and (min-width: 992px) {
  ul.navbar-nav {
    padding-right: 40px
  }
}

.nav .open>a {
  background: transparent;
  border-color: transparent
}

.nav .open>a:hover,
.nav .open>a:focus {
  background: transparent;
  border-color: transparent
}

.dropdown-submenu {
  position: relative
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -2px;
  border-radius: 0
}

.dropdown-submenu>a:after {
  display: block;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  float: right;
  margin-top: 0;
  margin-right: -5px
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff
}

.dropdown-submenu.pull-left {
  float: none
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px
}

.dropdown-menu {
  text-align: left;
  background: #1c1e22;
  z-index: 19;
  min-width: 240px;
  border-radius: 0;
  padding: 0;
  border: 0
}

.dropdown-menu.menu-center {
  left: -65px
}

@media screen and (min-width: 992px) {
  .dropdown-menu {
    padding: 10px 25px
  }
}

@media screen and (min-width: 991px) {
  .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    margin-top: 0
  }
}

.dropdown-menu-large {
  min-width: 400px
}

.dropdown-menu-large>li>ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.dropdown-menu-large>li>ul>li>a {
  padding-left: 0
}

.navbar-nav>li>.dropdown-menu a {
  background: none
}

.dropdown-menu li a {
  display: block;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  padding: 13px 0;
  border-bottom: 1px solid rgba(50, 52, 56, 0.45);
  color: #fff
}

.dropdown-menu li:last-child>a {
  border-bottom: 0 !important
}

.dropdown-menu li.active a {
  color: #fa4616
}

.navbar-nav .active>.nav-link {
  color: #fff
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff
}

.navbar-nav .show>.nav-link {
  color: #fff
}

.navbar-nav .nav-link {
  color: #fff
}

.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: #fff
}

.navbar-toggler-icon {
  background-image: none;
  color: #fff;
  width: 39px;
  height: 35px;
  border: 1px solid #fa4616;
  padding-top: 1px;
  background-color: #fa4616;
  font-size: 20px;
  line-height: 35px;
  margin: 12px 0
}

.navbar-toggler-icon:focus {
  outline: 0 solid
}

.dropdown-menu li a {
  -webkit-transition: all .3s;
  transition: all .3s;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
  color: #fa4616;
  font-weight: 600;
  padding-left: 6px;
  background: none !important
}

@media (min-width: 768px) {
  ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block
  }
}

@media (min-width: 767px) {
  ul.nav li.dropdown ul.dropdown-menu li.dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    display: none
  }

  ul.nav li.dropdown ul.dropdown-menu li.dropdown-submenu:hover .dropdown-menu {
    display: block
  }
}

.site-navigation .container {
  position: relative
}

.search-area {
  position: relative;
  min-height: 70px;
  padding-right: 0
}

.nav-search {
  position: absolute;
  cursor: pointer;
  top: 32px;
  right: 0;
  color: #fff;
  width: 30px;
  text-align: center
}

.search-block {
  background: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: none;
  padding: 15px 20px;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 97%;
  z-index: 10;
  margin-top: 0
}

.search-block .form-control {
  background: none;
  border: 0;
  color: #000;
  width: 100%;
  padding: 0 12px;
  font-size: 15px;
  font-style: italic
}

.search-block .search-close {
  color: #fff;
  position: absolute;
  top: -62px;
  right: -14px;
  font-size: 32px;
  cursor: pointer;
  padding: 5px;
  width: 30px;
  height: 30px;
  line-height: 14px;
  text-align: center
}

.search-area .nav-search {
  top: 23px
}

.search-area .search-block {
  right: 0
}

.search-area .search-block .search-close {
  top: -50px
}

.header-get-a-quote .btn-primary {
  padding: 13px 25px;
  border-radius: 3px;
  line-height: normal;
  color: #fff;
  position: relative;
  top: -12px
}

.header-get-a-quote .btn-primary:hover {
  background: #272d33
}

@media (min-width: 992px) and (max-width: 1199px) {
  .top-info li.header-get-a-quote {
    display: none
  }
}

ul.navbar-nav>li.header-get-a-quote:hover>a:after {
  position: relative;
  content: no-close-quote
}

#main-slide .carousel-item {
  height: 900px;
  color: #fff;
  background-position: 100% 100%;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden
}

#main-slide .carousel-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

#main-slide .carousel-item .slider-content {
  z-index: 1;
  opacity: 0;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms
}

#main-slide .carousel-item.active .slider-content {
  opacity: 1;
  -webkit-transition: opacity 100ms;
  transition: opacity 100ms;
  position: relative
}

#main-slide .slider-content {
  padding: 50px 0 0;
  width: 100%;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  min-height: 900px
}

.slide-title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 100;
  color: #fff;
  margin: 0
}

.slide-sub-title {
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  letter-spacing: -2px;
  font-size: 60px;
  line-height: 60px;
  margin: 10px 0 20px;
  color: #fff;
  font-weight: 200
}

.slider-text {
  display: table;
  vertical-align: bottom;
  color: #fff;
  padding-left: 40%;
  width: 100%;
  padding-bottom: 0;
  padding-top: 20px
}

.slider-text .slide-head {
  font-size: 36px;
  color: #0052a5;
  position: relative
}

.slider-img {
  display: block;
  position: absolute;
  top: -80px;
  left: 20%;
  width: 314px;
  max-height: 100%
}

.slider-img img {
  display: block;
  position: relative;
  max-height: 100%;
  width: auto
}

.slider.btn {
  margin: 15px 5px 0;
  border: 2px solid transparent
}

.slider.btn.btn-border {
  border: 2px solid #fff;
  color: #fff
}

.slider.btn.btn-border:hover {
  border: 2px solid transparent;
  color: #fff
}

.slider-description {
  color: #fff;
  font-size: 45px;
  line-height: 35px;
  margin: 20px 0;
  font-weight: bold
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  margin: 1px !important;
  border-radius: 100% !important;
  border: 0 !important;
  background: rgba(255, 255, 255, 0.5)
}

#main-slide .carousel-indicators li.active,
#main-slide .carousel-indicators li:hover {
  background: #fa4616;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  transition: all 300ms ease
}

#main-slide .carousel-control.left,
#main-slide .carousel-control.right {
  opacity: 0;
  filter: alpha(opacity=0);
  background-image: none;
  background-repeat: no-repeat;
  text-shadow: none;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  transition: all .25s ease
}

#main-slide .carousel-indicators {
  opacity: 0;
  filter: alpha(opacity=0);
  background-image: none;
  background-repeat: no-repeat;
  text-shadow: none;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  transition: all .25s ease
}

#main-slide:hover .carousel-control.left,
#main-slide:hover .carousel-control.right {
  opacity: 1;
  filter: alpha(opacity=100)
}

#main-slide:hover .carousel-indicators {
  opacity: 1;
  filter: alpha(opacity=100)
}

#main-slide .carousel-control .fa-angle-left,
#main-slide .carousel-control .fa-angle-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%)
}

#main-slide .carousel-control .fa-angle-left {
  left: 20px
}

#main-slide .carousel-control .fa-angle-right {
  right: 20px
}

#main-slide .carousel-control i {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  line-height: 58px;
  width: 60px;
  height: 60px;
  font-size: 36px;
  border-radius: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  transition: all 500ms ease
}

#main-slide .carousel-control i:hover {
  background: #fa4616;
  color: #fff
}

#main-slide .carousel-indicators {
  display: none !important
}

.carousel-inner .carousel-item.active h2,
.carousel-inner .carousel-item.active h3,
.carousel-inner .carousel-item.active p {
  -webkit-animation: fadeInLeft 1.5s ease-in-out 300ms both;
  animation: fadeInLeft 1.5s ease-in-out 300ms both
}

.owl-carousel.page-slider .owl-nav {
  margin: 0
}

.owl-carousel .owl-nav {
  text-align: center
}

.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  background: #fa4616;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  line-height: 34px;
  font-size: 20px;
  text-align: center;
  margin: 0;
  border-radius: 0;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.owl-carousel.owl-theme .owl-nav [class*=owl-prev] {
  right: 40px
}

.owl-carousel.owl-theme .owl-nav>div:hover {
  background: #272d33
}

.owl-carousel.owl-theme.owl-dark .owl-nav .disabled {
  background: #90a9e7;
  color: #fff;
  opacity: 1
}

.owl-theme .owl-dots {
  text-align: center;
  margin-top: 40px;
  position: relative;
  z-index: 1
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block
}

.owl-theme .owl-dots .owl-dot span {
  width: 30px;
  height: 4px;
  margin: 5px 4px;
  background: #000;
  opacity: .2;
  display: inline-block
}

.owl-theme .owl-dots .owl-dot.active span {
  background: #fa4616;
  opacity: 1
}

.page-slider.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  height: 100px;
  line-height: 100px;
  border-radius: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-top: -25px
}

.page-slider.owl-carousel.owl-theme .owl-nav [class*=owl-]:hover {
  background: #fa4616
}

.page-slider.owl-theme .owl-nav div {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  width: 36px;
  height: 72px;
  line-height: 72px;
  font-size: 26px;
  text-align: center;
  margin: 0;
  border-radius: 0;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%)
}

.page-slider.owl-theme .owl-nav div:hover {
  background: #fa4616;
  color: #fff
}

.page-slider.owl-theme .owl-nav [class*=owl-prev] {
  right: auto;
  left: 0
}

.page-slider .item {
  position: relative;
  z-index: 2;
  height: 700px
}

.page-slider .item img {
  width: 100%;
  height: 700px;
  -o-object-fit: cover;
  object-fit: cover
}

.page-slider.page-slider-small .item {
  max-height: 500px;
  min-height: auto
}

.page-slider .owl-item .box-slider-content {
  opacity: 0;
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s
}

.page-slider .owl-item.active .box-slider-content {
  opacity: 1;
  -webkit-transition: opacity .8s;
  transition: opacity .8s
}

.page-slider .owl-item.active .box-slider-text {
  -webkit-animation: slideInRight .8s ease-out 1s both;
  animation: slideInRight .8s ease-out 1s both
}

.page-slider-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 20px;
  background: rgba(255, 255, 255, 0.9)
}

.page-slider-caption h3 {
  font-size: 20px
}

.page-slider-caption span {
  font-weight: 400
}

.box-slider-content {
  top: 50%;
  padding: 0;
  position: absolute;
  width: 750px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 199
}

.box-slider-text {
  background: none;
  display: inline-block;
  padding: 20px 30px;
  max-width: 750px
}

.box-slider-text .slider.btn {
  padding: 12px 40px
}

.box-slider-text .slider.btn.btn-border {
  border: 2px solid #fa4616;
  color: #333f48
}

.box-slider-text .slider.btn.btn-border:hover {
  color: #fff;
  border-color: transparent
}

.box-slide-title {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  color: #fff
}

.box-slide-description {
  color: #fff
}

.box-slide-sub-title {
  font-size: 56px;
  color: #fff;
  margin: 8px 0 10px
}

.box-slide.owl-theme .owl-controls {
  margin: -20px
}

#main-slide.slider-pages .item {
  min-height: 340px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

section .ts-features [class*=col] {
  height: 360px;
  padding: 0
}

.feature-box1,
.feature-box2,
.feature-box3 {
  position: relative;
  background-size: cover;
  background-position: center
}

.ts-features-col {
  padding-top: 0;
  background: #2d2d2d
}

.ts-features-col .feature-box1,
.ts-features-col .feature-box2,
.ts-features-col .feature-box3 {
  overflow: hidden
}

.ts-features-col .feature-box1:after,
.ts-features-col .feature-box2:after,
.ts-features-col .feature-box3:after {
  background: #222
}

.ts-features-col .feature-box1:after {
  background: #fa4616
}

.ts-features-col .feature-box2:after {
  background: #97999b
}

.ts-feature-info {
  color: #fff;
  z-index: 1;
  position: relative;
  padding: 80px 20px
}

.ts-feature-title {
  font-size: 24px;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  letter-spacing: -1px;
  margin: 30px 0 20px;
  color: #fff;
  position: relative
}

.ts-feature-info span {
  color: #fff;
}

.ts-feature-info p {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  color: #9b9b9b;
  font-weight: 500;
  position: relative
}

.ts-feature-info i {
  font-size: 60px;
  color: #fff;
  position: relative
}

.ts-feature-info.color-1 i {
  color: #fa4616
}

.ts-feature-info.color-2 p {
  color: #9b9b9b !important
}

.ts-feature-info:hover p {
  color: #fff
}

.ts-feature-info-2 {
  color: #fff;
  z-index: 1;
  position: relative;
  padding: 50px 20px
}

/* .ts-feature-info-2:before{content:'';width:100%;height:100%;top:0;left:0;background:linear-gradient(to top,rgba(34,84,207,0.5) 0%,rgba(34,84,207,0.9) 100%),url(../images/intro-img.jpg);position:absolute;-webkit-transform:scale(0);transition:.3s ease} */
.ts-feature-info-2 p {
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  color: #9b9b9b;
  font-weight: 500;
  position: relative
}

.ts-feature-info-2 i {
  font-size: 60px;
  color: #fa4616;
  position: relative
}

.ts-feature-info-2:hover i,
.ts-feature-info-2:hover p {
  color: #fff
}

.ts-feature-info-2:hover:before {
  transform: scale(1)
}

.feature-box1:before,
.feature-box2:before,
.feature-box3:before {
  -wekbit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.feature-box1:hover:before,
.feature-box2:hover:before,
.feature-box3:hover:before {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background: linear-gradient(160deg, rgba(250, 70, 22, 0.85) 0%, rgba(250, 70, 22, 0.85) 35%, rgba(250, 70, 22, 0.85) 100%)
}

.ts-feature-info.icon-left .feature-icon {
  float: left;
  color: #3261D3
}

.ts-feature-info.icon-left .feature-icon i {
  font-size: 50px
}

.ts-feature-info.icon-left .feature-content {
  margin-left: 80px
}

.ts-feature-info.icon-left .feature-content h3 {
  color: #333f48
}

.ts-feature-info.icon-left .feature-content p {
  color: #97999b
}

.ts-feature-info.icon-left .ts-feature-title {
  font-size: 22px;
  margin: 0 0 5px
}

.no-padding .ts-service-pattern {
  padding: 100px 0
}

.ts-service-pattern {
  position: relative;
  z-index: 2
}

@media screen and (max-width: 991px) {

  .ts-service-pattern:before,
  .ts-service-pattern:after {
    display: none
  }
}

.ts-service-box {
  padding: 0
}

.ts-service-image-wrapper {
  overflow: hidden
}

.ts-service-image-wrapper img {
  max-width: 100%;
  -moz-transition: all .7s;
  -webkit-transition: all .7s;
  transition: all .7s
}

.ts-service-content {
  position: relative
}

.ts-service-content p {
  margin-bottom: 0
}

.ts-service-content p .link-more {
  margin-top: 10px;
  display: block;
  opacity: 0;
  visibility: visible;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.service-title {
  margin-top: 25px
}

.ts-service-box:hover img {
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  transform: scale(1.4)
}

.ts-service-box:hover .service-title {
  color: #fa4616
}

.ts-service-box:hover p .link-more {
  color: #333f48;
  opacity: 1
}

.sidebar .download-btn {
  border: 1px solid #eaeaea;
  padding: 20px 30px;
  font-size: 22px;
  margin: 0
}

.how-to-apply h3 {
  font-size: 24px;
  color: #fff
}

.how-to-apply p {
  color: #fff
}

.widget.box-primary {
  padding: 30px
}

.box-primary .btn {
  font-size: 16px;
  font-weight: 600
}

.service-menu {
  margin: 0;
  padding: 0;
  list-style: none
}

.service-menu li a {
  display: block;
  background: #f7f9fd;
  padding: 15px 20px;
  margin-bottom: 8px;
  color: #97999b;
  position: relative;
  -webkit-transition: all .3s;
  transition: all .3s
}

.service-menu li a:hover {
  background: #fa4616;
  color: #fff !important
}

.service-menu li a:before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  padding-right: 10px;
  font-weight: 900
}

.service-menu li:last-child a {
  margin-bottom: 0
}

.testimonial-static .quote-item {
  padding: 0
}

.testimonial-static .quote-item .quote-text:after {
  display: none
}

.testimonial-static .quote-item .quote-text:before {
  left: 50%;
  margin-left: -30px
}

.testimonial-static .quote-item .quote-subtext {
  color: #97999b
}

.testimonial-static .quote-item-footer {
  margin-top: 0;
  padding-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.testimonial-static .quote-classic .quote-text {
  text-align: center
}

.ts-testimonial {
  position: relative
}

.ts-testimonial .quotes-text {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  position: relative
}

.ts-testimonial .quotes-text span {
  color: #fff;
  font-family: 'Barlow', sans-serif;
  font-weight: 300;
  font-style: italic
}

.ts-testimonial .quotes-text i {
  color: #fa4616;
  font-weight: 700;
  font-size: 24px;
  padding-left: 20px
}

.ts-testimonial .quotes-img {
  margin-top: 20px
}

.ts-testimonial .quotes-img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 6px solid rgba(255, 255, 255, 0.3);
  display: initial
}

.ts-testimonial h2 {
  color: #fa4616;
  font-family: 'Barlow', sans-serif;
  font-size: 24px;
  padding: 0;
  margin: 0
}

.ts-testimonial p {
  color: #fff;
  font-family: 'Barlow', sans-serif;
  padding: 0;
  margin: 0
}

.ts-list {
  list-style: none;
  margin: 0;
  padding: 0
}

.ts-list li {
  display: block;
  margin-bottom: 20px;
  position: relative;
  padding-left: 25px
}

.ts-list li a {
  display: block
}

.ts-list li:last-child {
  margin-bottom: 0
}

.ts-list li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0c8";
  font-size: 8px;
  color: #333f48;
  position: absolute;
  top: 0;
  left: 0
}

.text-block blockquote.light {
  background: #f7f9fd;
  border-left: 5px solid #fa4616;
  color: #97999b;
  padding: 35px
}

.text-block blockquote.light p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400
}

.text-block blockquote:before {
  display: none
}

.skills-image {
  height: 565px;
  position: relative;
  overflow: hidden
}

.skills-image img {
  height: 100%;
  width: 100%
}

.intro-video {
  position: relative;
  width: 100%
}

.intro-video img {
  height: 580px;
  -o-object-fit: cover;
  object-fit: cover
}

.intro-video.img-height img {
  height: auto
}

.intro-video:after {
  -wekbit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%)
}

.intro-video:hover:after {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background: linear-gradient(160deg, rgba(250, 70, 22, 0.55) 0%, rgba(250, 70, 22, 0.55) 35%, rgba(250, 70, 22, 0.55) 100%)
}

.intro-video img {
  width: 100%
}

.intro-video:hover .video-icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg)
}

.ts-facts-area [class*=col] {
  padding: 0
}

.ts-facts-area .ts-padding {
  padding: 70px 140px 0 50px
}

.ts-facts-area .boxed-ts-padding {
  padding: 20px 40px 0 50px
}

.ts-facts-area .column-title {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 10px
}

.ts-facts i {
  font-size: 48px;
  color: #a4a7af
}

.ts-facts-num {
  font-size: 30px;
  font-weight: 700;
  color: #333f48;
  margin-top: 15px;
  margin-bottom: 0;
  display: inline-block
}

.facts h4 {
  font-size: 18px;
  margin-top: 8px;
  text-transform: uppercase
}

.ts-facts-area-bg {
  padding: 60px 0;
  color: #fff;
  background-color: #fa4616;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 50%
}

.ts-facts-bg i {
  font-size: 48px;
  color: #fff
}

.ts-facts-bg .ts-facts-num {
  color: #fff
}

.ts-facts-area-bg.color-2 .ts-facts-bg i {
  color: #fa4616
}

.ts-facts-area-bg.color-2 .ts-facts-num {
  color: #404044
}

.ts-facts-area-bg.color-2 .facts-desc {
  color: #404044
}

.ts-intro {
  padding-bottom: 80px;
  background: #f1f1f1
}

.ts-intro .btn-primary {
  margin-top: 15px
}

.ts-intro .column-title {
  font-size: 36px;
  line-height: 44px
}

.ts-intro .pull-right.btn-primary {
  margin-right: 20px
}

.intro-image-box {
  position: relative;
  margin: 20px;
  -ms-transform: rotate(+90deg);
  -webkit-transform: rotate(+90deg);
  transform: rotate(+90deg)
}

.clip-text {
  font-size: 6em;
  font-weight: 700;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 120px 50px;
  text-align: center;
  color: #fff;
  -webkit-text-fill-color: transparent
}

.clip-text:before,
.clip-text:after {
  position: absolute;
  content: ''
}

.clip-text:before {
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit
}

.clip-text:after {
  position: absolute;
  z-index: -1;
  top: .195em;
  right: .195em;
  bottom: .195em;
  left: .195em;
  background-color: #fff
}

/* .clip-text_one{background-image:url(../images/intro-img.jpg)} */
.title {
  font-size: 2em;
  position: relative;
  margin: 0 auto 1em;
  padding: 1em 1em .25em;
  text-align: center;
  text-transform: uppercase
}

.title:after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 240px;
  height: 4px;
  margin-left: -120px;
  content: '';
  background-color: #fff
}

.call-to-action-title {
  font-size: 30px;
  color: #333f48;
  line-height: 48px
}

.call-to-action {
  padding: 70px 0
}

.call-to-action p {
  font-size: 18px;
  font-weight: 500;
  color: #333f48;
  line-height: 28px
}

.call-to-action .btn {
  font-weight: 800;
  margin-top: 10px
}

.call-to-action h3 {
  margin-bottom: 10px
}

.call-to-action.style-1 h3 {
  color: #fff
}

.call-to-action.style-1 p {
  color: #fff
}

/* .call-to-action-bg{padding:70px;background-image:url(../images/parallax/parallax1.jpg);background-attachment:fixed;background-size:cover;background-position:center} */
.call-to-action-bg-2 {
  padding: 70px;
  background: #151719;
  position: relative
}

/* .call-to-action-bg-2:before{content:'';top:0;left:0;width:100%;height:100%;background:url(../images/portfolio-bg.png);position:absolute} */
.call-to-action-bg h3 {
  margin-bottom: 10px
}

.call-to-action-bg h3,
.call-to-action-bg p {
  color: #fff
}

.call-to-action-bg p {
  margin-bottom: 0
}

.ts-about-us {
  position: relative;
  margin-top: 50px
}

.ts-about-us .about-image {
  padding: 0;
  margin: 0
}

.ts-about-us .about-image img {
  width: 100%
}

.about-img-info img {
  max-width: 100%
}

.ts-about-intro {
  position: relative
}

/* .ts-about-intro:before{content:'';top:0;right:0;width:273px;height:274px;background:url(../images/pattern/pattern_arrow-2.png) no-repeat;position:absolute} */
.ts-about-intro .ts-image-info {
  position: relative
}

.ts-about-intro .ts-image-info img {
  height: 450px;
  padding: 10px;
  position: relative;
  border: 4px solid #fa4616
}

.ts-about-intro .ts-image-info:before {
  content: '';
  left: 120px;
  top: 20px;
  width: 350px;
  height: 450px;
  background: #fa4616;
  position: absolute
}

.about-img-intro {
  position: relative;
  width: 100%
}

.about-img-intro:after {
  -wekbit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  background: linear-gradient(160deg, rgba(250, 70, 22, 0.65) 0%, rgba(250, 70, 22, 0.65) 35%, rgba(250, 70, 22, 0.65) 100%)
}

.featured-tab {
  margin-top: -20px
}

.featured-tab .nav-tabs {
  border: 0
}

.featured-tab .nav-tabs>li {
  width: 25%
}

.featured-tab .nav-tabs>li>a {
  font-weight: 600;
  background: none;
  border-radius: 0;
  min-height: 100px;
  padding: 25px 5px;
  border: 0;
  margin-right: -1px;
  color: #333f48;
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease
}

.featured-tab .nav-tabs>li>a.active {
  color: #fa4616;
  background: #fff;
  -webkit-box-shadow: 0 -5px 14px -5px rgba(0, 0, 0, 0.05), -5px 0 14px -13px rgba(0, 0, 0, 0.05);
  box-shadow: 0 -5px 14px -5px rgba(0, 0, 0, 0.05), -5px 0 14px -13px rgba(0, 0, 0, 0.05)
}

.tab-text-title {
  display: block;
  margin-top: 20px
}

.featured-tab .tab-content {
  background: #fff;
  border: 0;
  border-top: 0;
  padding: 40px 30px;
  min-height: 430px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05)
}

@media screen and (min-width: 992px) {
  .featured-tab .bg-contain-verticle {
    min-height: 290px
  }
}

.bg-contain-verticle {
  background-size: cover;
  background-repeat: no-repeat;
  vertical-align: middle !important;
  padding: 30px 25px;
  margin: -10px
}

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto
}

.tab-content-info {
  padding-left: 30px
}

.tab-content-info .btn-light {
  font-weight: 800;
  -webkit-transition: all .3s;
  transition: all .3s;
  background-color: transparent;
  border-color: transparent
}

.tab-content-info .btn-light i {
  padding-left: 5px
}

.tab-content-info .btn-light:hover {
  color: #fa4616;
  background-color: transparent
}

.tab-content-title {
  font-size: 30px;
  line-height: 22px;
  margin-top: 40px;
  margin-bottom: 30px;
  display: inline-block
}

@media screen and (max-width: 479px) {
  .tab-content-title {
    line-height: 36px
  }
}

.featured-tab .tab-pane img.pull-left {
  margin: 0 40px 10px 0
}

.featured-tab .tab-pane img.pull-right {
  margin: 0 0 0 40px
}

.featured-tab .nav-link {
  position: relative;
  z-index: 2
}

.featured-tab .nav-link.active:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: "";
  width: 100px;
  height: 3px;
  background: #2154ce;
  margin-left: -50px;
  -webkit-transition: all .3s;
  transition: all .3s
}

.featured-tab .nav-link.active .tab-head span {
  color: #2154ce
}

.tab-head span {
  -webkit-transition: all .3s;
  transition: all .3s;
  color: #222
}

.tab-head span i {
  font-size: 45px;
  -webkit-transition: all .3s;
  transition: all .3s;
  color: #2154ce
}

/* .ts-particles{color:#fff;background-color:#1c1c24;background-image:url(../images/parallax/parallax1.jpg);background-repeat:no-repeat;background-size:cover;background-attachment:fixed;background-position:50% 50%;padding:100px 0} */
.ts-particles a {
  position: relative;
  z-index: 1999999
}

#graph {
  width: 100%;
  margin: 0 auto
}

.ts-featured-projects {
  padding-top: 70px
}

#featured-cases-slide {
  position: relative
}

#featured-cases-slide .owl-stage-outer {
  padding-top: 75px
}

#featured-cases-slide .item {
  min-height: 470px
}

.featured-projects-content {
  min-height: 470px;
  padding: 0 45px;
  margin: 0 30px 20px;
  border-radius: 3px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none
}

.featured-projects-content.margin-none {
  margin: 0
}

.featured-projects-text {
  max-width: 510px;
  padding: 50px 30px 0 0
}

.arrow-text {
  top: 0;
  left: 5%;
  width: 100%;
  position: absolute;
  z-index: 19
}

.arrow-text i {
  font-size: 100px;
  color: #fa4616;
  opacity: .1
}

.featured-projects-text.pull-right {
  padding: 50px 0 0 30px
}

.features-slider-img {
  margin-top: -75px;
  position: relative
}

.img-shadow:before,
.img-shadow:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 25px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  -webkit-box-shadow: 0 35px 20px #a8a8a8;
  -moz-box-shadow: 0 35px 20px #a8a8a8;
  box-shadow: 0 35px 20px #a8a8a8;
  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  transform: rotate(-8deg)
}

.img-shadow:after {
  -webkit-transform: rotate(8deg);
  -moz-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
  right: 10px;
  left: auto
}

.featured-projects-content .btn-primary {
  margin-top: 15px
}

.ts-case-box {
  margin-bottom: 40px;
  overflow: hidden
}

.ts-case-box:hover .ts-case-image-wrapper:before {
  opacity: .3;
  visibility: visible;
  bottom: 0
}

.ts-case-box.marg-none {
  margin-bottom: 0
}

.case-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  color: #fff;
  margin-bottom: 5px
}

.case-title small {
  display: block;
  font-style: italic;
  font-size: 14px;
  line-height: 28px;
  position: relative;
  padding-left: 10px
}

.case-title small:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 3px;
  height: 13px;
  content: "";
  background: #fa4616;
  margin-top: -7px
}

.ts-case-content {
  position: absolute;
  bottom: 0;
  padding: 35px 35px 10px;
  width: 100%;
  z-index: 3
}

.ts-case-content p {
  line-height: 28px;
  color: #fff
}

.ts-case-content a {
  font-weight: 900;
  color: #fff;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ts-case-content a:hover {
  color: #fa4616
}

.ts-case-image-wrapper {
  position: relative;
  z-index: 3
}

.ts-case-image-wrapper img {
  width: 100%
}

.ts-case-image-wrapper:before {
  position: absolute;
  content: "";
  bottom: -100%;
  left: 0;
  background: #000;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s linear;
  transition: all .3s linear
}

.ts-case-image-wrapper:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.9)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 40%, rgba(0, 0, 0, 0.9) 100%);
  z-index: 2
}

.ts-single-case-info {
  padding: 100px 0
}

.ts-case-challange {
  padding-bottom: 100px
}

.ts-case-result {
  padding-bottom: 100px
}

.case-img-left {
  padding-top: 100px
}

.case-details-inner .heading {
  margin-bottom: 40px
}

.case-details-inner .ts-feature-info {
  padding: 0
}

.case-details-inner .ts-feature-info i {
  color: #fa4616
}

.case-details-inner .ts-feature-title {
  color: #000
}

.case-details-inner .feature-content p {
  color: #000
}

.content-title.border-none {
  border-left: 0;
  padding-left: 0;
  line-height: 44px;
  margin-bottom: 0;
  font-size: 36px
}

.box-solid {
  background: #f7f9fd
}

.list-style-classic {
  padding: 30px
}

.list-style-classic ul {
  list-style: none;
  margin: 0;
  padding: 0
}

.list-style-classic li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.list-style-classic li div {
  width: 100%
}

.list-style-classic li:last-child {
  margin-bottom: 0
}

.list-style-classic .classic-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 45px
}

.case-info-content {
  color: #97999b
}

.case-info-label {
  font-weight: 900;
  color: #333f48
}

.case-counter {
  margin: 25px 0
}

.case-counter p {
  color: #fa4616;
  font-size: 30px
}

.case-counter h3 {
  font-size: 24px;
  color: #333f48
}

.owl-carousel.featured-cases-slide.owl-theme .owl-nav>div {
  top: 50%;
  bottom: 0;
  right: 15px;
  width: 60px;
  height: 60px;
  line-height: 56px;
  font-size: 26px;
  margin-top: -50px
}

.owl-carousel.featured-cases-slide.owl-theme .owl-nav>.owl-prev {
  right: 73px
}

.owl-carousel.featured-cases-slide.owl-theme .owl-nav>.disabled {
  background: #90a9e7
}

.ts-team-wrapper {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.03);
  text-align: center;
  min-height: 380px
}

.team-img-wrapper img {
  max-height: 400px;
  border-radius: 5%
}

.ts-team-content {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  height: 95px;
  padding: 22px 25px;
  margin-top: 0;
  -webkit-transition: 350ms;
  transition: 350ms;
  transition: 350ms
}

.team-img-wrapper {
  position: relative;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms
}

.team-img-wrapper:after {
  position: absolute;
  content: '';
  background: rgba(250, 70, 22, 0.8);
  width: 256px;
  height: 256px;
  border-radius: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms
}

.ts-team-content .team-name {
  font-size: 20px;
  margin: 0;
  line-height: normal
}

.ts-team-content .team-designation {
  font-size: 14px;
  font-style: italic
}

.team-social-icons {
  display: none;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms
}

.ts-team-info.team-list-border {
  border: 1px solid #f0f0f0;
  border-top: 0;
  padding: 20px 30px
}

.ts-team-info.team-list-border .team-social-classic {
  border-top: 1px solid #f0f0f0;
  padding-top: 20px;
  margin-top: 20px
}

.ts-team-wrapper:hover .ts-team-content {
  position: absolute;
  top: 50%;
  margin-top: -95px;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms
}

.ts-team-wrapper:hover .ts-team-content h3,
.ts-team-wrapper:hover .ts-team-content p {
  color: #fff
}

.ts-team-wrapper:hover .team-img-wrapper:after {
  opacity: 1;
  right: 0
}

.ts-team-wrapper:hover .team-social-icons {
  display: block;
  height: 95px;
  padding: 30px 0
}

.team-social-icons a {
  color: #333f48;
  margin-right: 12px;
  text-align: center
}

.team-social-classic a {
  display: inline-block;
  color: #000;
  padding-right: 20px
}

.team-social-classic a:hover {
  color: #97999b
}

.ts-team-classic {
  position: relative
}

.ts-team-info {
  position: relative;
  left: 0;
  padding: 20px 0 0;
  margin-top: 0
}

.ts-team-info h3 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 0
}

.ts-team-info p {
  text-align: center;
  margin-top: 0
}

.ts-team-info .team-designation {
  font-size: 14px;
  text-align: center;
  font-style: italic
}

.ts-team-classic img {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.ts-team-classic:hover img {
  -webkit-transform: translate(0px, -10px);
  -moz-transform: translate(0px, -10px);
  -ms-transform: translate(0px, -10px);
  -o-transform: translate(0px, -10px);
  transform: translate(0px, -10px)
}

.ts-team-classic:hover .ts-team-info h3 {
  color: #fa4616
}

.ts-team-bg {
  background-color: #F8F9FD
}

.quote-area {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  margin: 80px 0 60px
}

.quote_form {
  background: #333f48;
  color: #fff;
  padding: 80px
}

.quote_form .column-title {
  font-size: 36px;
  margin-bottom: 40px
}

.quote_form .column-title span {
  font-size: 20px;
  margin: 0
}

.quote_form .form-group {
  margin-bottom: 30px
}

.quote-form-text {
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px
}

.quote_form input::input-placeholder,
.quote_form textarea::input-placeholder {
  color: #fff;
  color: rgba(255, 255, 255, 0.3)
}

.newsletter-form input::input-placeholder,
.newsletter-form input:-moz-placeholder,
.newsletter-form input::-moz-placeholder {
  color: #333f48
}

.quote_form input:-moz-placeholder,
.quote_form textarea:-moz-placeholder,
.quote_form input::-moz-placeholder,
.quote_form textarea::-moz-placeholder {
  color: #fff
}

.newsletter-form input::-moz-placeholder {
  color: #fff
}

.quote_form input:-ms-input-placeholder,
.quote_form textarea:-ms-input-placeholder {
  color: #fff
}

.newsletter-form input:-ms-input-placeholder {
  color: #fff
}

.quote_form .form-control {
  border: 0;
  border-bottom: 1px solid #6d6d6d;
  padding-left: 0;
  color: #fff;
  background: none
}

.quote_form .form-control:focus {
  border: 0;
  border-bottom: 1px solid #fa4616;
  background: none
}

.quote_form .tag-line {
  text-align: center;
  color: #fff;
  clear: both;
  margin-top: 20px;
  margin-bottom: 0
}

.quote_form .tag-line p {
  color: #fff;
  margin: 0;
  font-size: 14px
}

.testimonial-slide .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%
}

.testimonial-slide {
  padding: 50px 0
}

@media screen and (min-width: 992px) {
  .quote-item.quote-left {
    padding-right: 50px
  }
}

@media screen and (min-width: 992px) {
  .quote-item.quote-right {
    padding-left: 50px
  }
}

.quote-item .quote-text {
  margin: 40px 0 10px;
  display: inline-block;
  padding: 50px 40px 30px 45px;
  background-color: #fff;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: relative;
  font-size: 16px;
  font-style: italic
}

.quote-item .quote-text:before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  font-size: 20px;
  left: 45px;
  top: -20px;
  color: #fff;
  background: #fa4616;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 3px;
  font-style: normal
}

.quote-item .quote-text:after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 55px;
  border-width: 0 0 20px 20px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.5);
  display: block;
  width: 0
}

.quote-item.quote-square .quote-text:before {
  content: "\e937";
  font-family: 'iconfont';
  font-size: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid #fa4616;
  line-height: 50px;
  color: #fff;
  background: #fa4616;
  border-radius: 3px;
  left: 45px
}

.ts-testimonial-static {
  padding: 100px 0
}

.ts-testimonial-static .quote-classic {
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);
  border: none;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ts-testimonial-static .quote-classic .quote-text {
  text-align: center
}

.ts-testimonial-static .quote-classic:hover {
  -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06)
}

.quote-item-area {
  max-width: 600px;
  margin: 0 auto
}

.quote-item-area .quote-thumb {
  text-align: center
}

.quote-item-area .quote-thumb img {
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 100%;
  padding: 10px;
  border: 3px solid #fa4616
}

.quote-item-content .quote-name {
  margin: 0;
  color: #fa4616
}

.quote-item-content .quote-name-desg {
  margin-bottom: 10px;
  font-family: 'Barlow', sans-serif;
  font-weight: 700;
  display: block;
  font-size: 13px;
  color: #222
}

.quote-message {
  font-style: italic;
  color: #777
}

.testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  left: 0;
  top: 50%
}

.testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-next] {
  left: auto;
  right: -200px
}

.testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-prev] {
  right: auto;
  left: -200px
}

.ts-testimonial-slide.image-bg .owl-nav {
  width: 100%;
  left: 0;
  bottom: 50%
}

.ts-testimonial-slide.image-bg .owl-nav .owl-next {
  left: auto;
  right: 0
}

.ts-testimonial-slide.image-bg .testimonial-slide.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: 0
}

.ts-testimonial-slide.image-bg .testimonial-slide.owl-carousel .owl-nav .owl-prev {
  left: 0
}

img.testimonial-thumb {
  max-width: 80px;
  float: left;
  margin-right: 20px;
  border-radius: 100%
}

.quote-item-info {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.quote-author {
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 2px;
  font-weight: 600;
  display: block;
  color: #fff
}

.quote-subtext {
  color: #fff;
  font-size: 14px
}

.quote-item-footer {
  margin-top: 40px;
  margin-left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.ts-testimonial-slide {
  padding: 100px 0;
  margin-bottom: -40px
}

.testimonial-slide.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  bottom: 90px
}

.testimonial-slide.owl-theme .owl-dots {
  text-align: center;
  margin-top: 60px;
  position: relative;
  z-index: 1
}

.testimonial-slide.owl-theme .owl-dots .owl-dot {
  display: inline-block
}

.testimonial-slide.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 4px;
  background: #fff;
  opacity: .45;
  display: inline-block
}

.testimonial-slide.owl-theme .owl-dots .owl-dot.active span {
  background: #e50404;
  opacity: 1
}

.owl-carousel.owl-theme.partners .owl-nav {
  margin: 0
}

.partners-carousel .owl-theme .owl-nav {
  margin-top: 0
}

.clients-area {
  background: #f7f9fd;
  padding: 70px 0
}

.partner-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  min-height: 32px;
  margin-bottom: 0
}

.partner-logo img {
  -webkit-transition: 350ms;
  transition: 350ms;
  transition: 350ms;
  width: auto !important
}

.partner-logo:hover img {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%)
}

.owl-carousel.owl-theme.partners .owl-nav .owl-prev {
  left: 0;
  right: auto;
  background: #fa4616;
  color: #fff;
  -webkit-transition: all .3s;
  transition: all .3s
}

.owl-carousel.owl-theme.partners .owl-nav .owl-prev:hover {
  background: #272D33
}

.owl-carousel.owl-theme.partners .owl-nav [class*=owl-] {
  bottom: -12px;
  margin-bottom: -12px
}

.accordion-title span {
  color: #97999b;
  font-weight: 600;
  font-size: 16px
}

.panel-default>.panel-heading {
  background: none;
  border-radius: 0;
  position: relative;
  padding: 6px 20px
}

.panel-group .panel {
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #000
}

.panel-body {
  padding: 20px 20px 10px;
  border: 1px solid #DDD;
  border-radius: 0 11px 0 0;
  margin-left: 15px
}

.panel-body>img {
  max-width: 100px;
  margin-bottom: 10px
}

p.panel-title {
  font-size: 16px;
  background: none;
  border: 0;
  padding: 8px 0;
  line-height: normal;
  border-radius: 0
}

p.panel-title a {
  color: #fa4616
}

p.panel-title span {
  float: right
}

p.panel-title a.collapsed {
  color: #97999b
}

.panel-classic .panel-heading {
  padding-left: 15px
}

.panel-classic h4.panel-title a:before,
.panel-classic h4.panel-title a.collapsed:before {
  position: relative;
  content: '';
  font-size: 0
}

.clients-logo {
  margin: 0 0 30px;
  border: 1px solid #dadada;
  min-height: 105px;
  padding: 0 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center
}

.clients-logo img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray
}

.latest-post {
  position: relative;
  padding: 0;
  min-height: 345px
}

.latest-post.latest-post-height {
  min-height: 0
}

.latest-post .image-info {
  width: 100%;
  overflow: hidden
}

.latest-post .image-info img {
  max-width: 100%;
  -moz-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.latest-post .image-angle:before {
  border-bottom: 20px solid #f9f9f9
}

.latest-post .post-title {
  font-family: 'Barlow', sans-serif;
  letter-spacing: -1px;
  font-size: 20px;
  line-height: 28px;
  margin: 10px 0 5px
}

.latest-post .post-title a {
  color: #333f48
}

.latest-post .post-title a:hover {
  color: #fa4616
}

.latest-post-meta {
  font-size: 13px;
  text-transform: uppercase
}

.latest-post .post-cat,
.latest-post .post-item-date {
  font-style: italic;
  font-size: 14px
}

.latest-post .post-text {
  margin-top: 10px
}

.latest-post .post-text a {
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background: #f1f1f1;
  padding: 10px 20px;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s
}

.latest-post .post-body {
  padding: 0;
  background: none;
  border: 0
}

.latest-post.post-large {
  position: relative;
  padding: 0;
  border: 0;
  overflow: hidden
}

.latest-post.post-large:before {
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%
}

.latest-post.post-large .post-body {
  position: absolute;
  bottom: 0;
  padding: 30px;
  border: 0;
  color: #fff
}

.latest-post.post-large .post-cat {
  position: absolute;
  top: 30px;
  left: 30px;
  color: #fff;
  font-style: italic
}

.latest-post.post-large .post-title {
  font-size: 32px;
  line-height: 40px
}

.latest-post.post-large.size-1 .post-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px
}

.latest-post.post-large.size-1 .btn.btn-primary {
  padding: 8px 20px;
  font-size: 14px
}

.latest-post.post-large .post-title a {
  color: #fff
}

.latest-post.post-large .post-title a:hover {
  color: #fa4616
}

.latest-post.post-large .btn.btn-primary {
  padding: 12px 30px;
  margin-top: 15px
}

.latest-post:hover .image-info img {
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3)
}

.latest-post:hover .post-text a {
  background: #fa4616;
  color: #fff
}

.latest-post:hover .post-text a:hover {
  background: #222;
  color: #fff
}

.footer {
  position: relative;
  z-index: 4
}

.footer-top {
  width: 100%
}

.footer-top-bg {
  background: #f7f9fd;
  margin: 0;
  color: #fff
}

.footer-top .footer-box {
  padding: 30px 45px 20px;
  min-height: 120px
}

.footer-top .footer-box i,
.footer-top .footer-box span {
  float: left;
  font-size: 28px;
  margin-top: 0;
  background: #fa4616;
  text-align: center;
  width: 65px;
  height: 65px;
  line-height: 65px
}

.footer-top .footer-box .footer-box-content {
  margin-left: 90px
}

.footer-top .footer-box .footer-box-content h3 {
  margin-top: 6px;
  margin-bottom: 2px;
  font-size: 20px;
  color: #333f48
}

.footer-top .footer-box .footer-box-content p {
  margin: 0;
  color: #777;
  font-size: 14px
}

.footer-main {
  position: relative;
  padding: 50px 0 20px
}

.footer-main.bg-overlay:after {
  background-color: rgba(51, 63, 72, 0.85)
}

.footer-widget {
  padding-bottom: 40px
}

.footer .widget-title {
  color: #fff;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 30px
}

.footer-about {
  padding-right: 40px
}

.footer-logo {
  margin: 20px 0 25px
}

.footer-social {
  margin-top: 25px
}

.footer-social ul {
  list-style: none;
  margin: 0;
  padding: 0
}

.footer-social ul li {
  margin: 0 10px 0 0;
  display: inline-block
}

.footer-social ul li a {
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms;
  color: #fff
}

.footer-social ul li a:hover {
  color: #fa4616
}

.footer-social ul li a i {
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms
}

.footer-social ul li a i:hover {
  color: #fff
}

.footer-social ul li a i.fa-facebook:hover {
  background: #3b5998
}

.footer-social ul li a i.fa-twitter:hover {
  background: #70c2e9
}

.footer-social ul li a i.fa-google-plus:hover {
  background: #c53942
}

.footer-social ul li a i.fa-pinterest:hover {
  background: #d8545d
}

.footer-social ul li a i.fa-linkedin:hover {
  background: #3daccf
}

.footer-social ul li a i.fa-instagram:hover {
  background: #3f729b
}

.footer-widget ul.list-dash li {
  line-height: normal;
  padding-bottom: 0;
  font-size: 15px;
  text-indent: -20px;
  line-height: 20px;
  margin-left: 20px
}

.footer-widget ul.list-dash li a {
  color: #fff
}

.footer-widget ul.list-dash li a:hover {
  color: #fa4616
}

.footer-widget ul.list-dash li a i {
  padding-right: 10px
}

.newsletter-widget {
  border: 0;
  padding-top: 0
}

.newsletter-widget .widget-title {
  margin-top: 0;
  line-height: 40px;
  margin-bottom: 25px
}

.newsletter-widget .widget-title span {
  font-size: 36px;
  display: block;
  text-transform: uppercase
}

.newsletter-form {
  position: relative
}

.newsletter-form input {
  width: 80%;
  margin: 15px 0;
  background: #fff;
  color: #333;
  font-size: 14px;
  height: 50px;
  font-style: italic;
  border: 0
}

.newsletter-form .btn.btn-primary {
  position: absolute;
  right: 13px;
  top: 0;
  padding: 13px 20px;
  height: 50px
}

.copyright {
  background: #97999b;
  color: white;
  padding: 30px 0;
  font-size: 15px
}

.footer-menu {
  float: right
}

.footer-menu ul li {
  display: inline-block;
  padding-left: 8px
}

.footer-menu ul li a {
  color: white;
  padding: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  line-height: 10px;
  padding-right: 10px;
  margin-right: 5px
}

.footer-menu ul li a:hover {
  color: #fa4616
}

.footer-menu ul li:last-child a {
  border-right: 0
}


#preloader {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  background: #fff;
  line-height: 1
}

/* #status,.biz-preloader{background-image:url(../images/preloder.gif);background-position:center;background-repeat:no-repeat;height:200px;left:50%;margin:-100px 0 0 -100px;position:absolute;top:50%;width:200px} */
.biz-preloader {
  background: none;
  left: 0;
  margin: 0;
  text-align: center;
  top: 65%
}

#back-to-top {
  position: fixed;
  right: 40px;
  top: auto;
  z-index: 10;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none
}

#back-to-top.affix {
  bottom: 25px
}

#back-to-top .btn.btn-primary {
  font-size: 18px;
  padding: 6px 15px;
  width: 44px;
  height: 44px;
  line-height: 30px
}

.main-container .border-left {
  margin-bottom: 30px
}

.banner-area {
  position: relative;
  min-height: 500px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

/* .banner-area:before{content:'';width:100%;height:100%;top:0;left:0;background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/banner.jpg);background-size:cover;background-position:center;position:absolute} */
/* .banner-area.quienes-somos:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/quienes_somos.jpg)} */
/* .banner-area.corredora-bolsa:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/corredora_bolsa.jpg)} */
/* .banner-area.fondos-inversion:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/fondos_inversion.jpg)} */
/* .banner-area.informacion-clientes:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/informacion_clientes.jpg)} */
/* .banner-area.napv:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/napv.jpg)} */
/* .banner-area.noticias:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/noticias.jpg)} */
/* .banner-area.inmobiliaria:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/inmobiliaria.jpg)} */
/* .banner-area.ntrade:before{background:linear-gradient(to top,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%),url(/static/images/banners/ntrade.jpg)} */
.banner-area .container {
  position: relative
}

.banner-title {
  color: #fff;
  font-family: 'Barlow', sans-serif;
  font-size: 65px;
  letter-spacing: -2px;
  margin-bottom: 30px;
  line-height: 1
}

.ban.banner-title.noticia {
  font-size: 50px;
  text-align: center
}

.banner-heading {
  padding: 100px 0 0;
  width: 100%;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  min-height: 500px
}

.breadcrumb {
  bottom: 0;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  font-weight: 300
}

.breadcrumb a {
  color: #fff
}

.breadcrumb i {
  padding: 0 10px;
  color: #fa4616
}

.breadcrumb a:hover {
  color: #fa4616
}

.banner__breadcrumb {
  font-size: 30px;
  font-weight: 300;
  color: #FFF;
  margin-top: 200px;
  text-align: center;
}

.banner__breadcrumb li {
  display: inline;
  margin-right: 20px;
}

.banner__breadcrumb li a {
  color: #FFF;
}

.banner__breadcrumb li a span,
.banner__breadcrumb li a:hover span,
.banner__breadcrumb li span {
  color: #fa4616 !important;
}

.about-pattern {
  position: relative;
  z-index: 2;
  padding: 80px 0
}

/* .about-pattern:before{position:absolute;top:0;left:0;width:100%;height:100%;content:"";background:url(../images/pattern/bg_pattern1.png) 100% 100%;z-index:-1} */
.about-desc p {
  font-weight: 500
}

.ceo-designation {
  display: inline-block
}

.ceo-designation p {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: -10px
}

.ceo-designation small {
  font-size: 14px
}

.ceo-desc {
  display: inline-block
}

.ceo-signature {
  margin-top: 35px
}

.ceo-signature>img {
  float: right
}

.ceo-signature:after {
  display: block;
  content: "";
  clear: both
}

.ceo-desc img {
  width: 95px;
  height: 95px;
  border-radius: 50%
}

.ts-skills-area .ts-padding {
  padding-bottom: 50px;
  padding-right: 80px
}

.ts-progress-bar {
  margin-bottom: 30px
}

.progress {
  height: 5px;
  position: relative;
  overflow: visible
}

.progress .progress-value {
  display: block;
  font-size: 16px;
  color: #000;
  position: absolute;
  top: -25px;
  right: 0
}

.ts-progress-bar h3 {
  font-size: 18px;
  text-align: left;
  margin: 0 0 10px
}

.ts-features-light {
  margin: 0
}

.ts-features-light.bg-blue {
  background: #fa4616
}

.ts-features-light .ts-feature-box {
  position: relative;
  padding: 10px 0
}

.ts-features-light .ts-feature-info i {
  color: #2B5CD1
}

.ts-features-light .ts-feature-info h3 {
  font-size: 24px
}

.ts-features-light .ts-feature-box .ts-feature-info {
  padding: 0 20px
}

.ts-features-light .ts-feature-box .ts-feature-info p {
  margin-bottom: 0
}

.ts-features-light .ts-feature-box:before {
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  background: #fa4616;
  width: 100%;
  height: 0;
  transition: .5s ease;
  position: absolute
}

.ts-features-light .ts-feature-box:hover:before {
  height: 100%
}

.ts-features-light .ts-feature-box:hover i,
.ts-features-light .ts-feature-box:hover h3,
.ts-features-light .ts-feature-box:hover p {
  color: #fff
}

.ts-feature-box .ts-feature-title {
  font-size: 20px;
  margin: 20px 0 10px;
  color: #000
}

.ts-feature-box .ts-feature-info {
  color: #000;
  z-index: 1;
  position: relative;
  padding: 15px
}

.feature-text-box .column-title {
  font-size: 36px;
  line-height: normal;
  margin-bottom: 10px
}

.feature-text-box .column-title span {
  font-size: 20px
}

.feature-text-box .btn {
  padding: 15px 60px;
  margin-top: 10px
}

.ts-features-light.style-1 .ts-feature-info h3,
.ts-features-light.style-1 .ts-feature-info i,
.ts-features-light.style-1 .ts-feature-info p {
  color: #fff
}

.ts-features-light.style-1 .ts-feature-box:before {
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  background: #1d4bba;
  width: 100%;
  height: 0;
  transition: .5s ease;
  position: absolute
}

.ts-features-light.style-1 .ts-feature-box:hover:before {
  height: 100%
}

.panel-heading i {
  background: #fa4616;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 15px
}

.help-box,
.ts-testimonial-static {
  background-color: #FFF
}

.help-box .help,
.ts-testimonial-static .help {
  background-color: blue;
  padding: 30px;
  color: #fff
}

.help-box .quote-item,
.ts-testimonial-static .quote-item {
  padding-left: 0
}

.help-box .quote-item .quote-text:before,
.ts-testimonial-static .quote-item .quote-text:before {
  left: 50%;
  margin-left: -35px
}

.help-box .quote-item .quote-text:after,
.ts-testimonial-static .quote-item .quote-text:after {
  display: none
}

.help-box .quote-item .quote-item-footer,
.ts-testimonial-static .quote-item .quote-item-footer {
  margin-top: 0;
  margin-left: 75px
}

.help-box .quote-item .quote-item-info,
.ts-testimonial-static .quote-item .quote-item-info {
  padding-top: 20px
}

.help-box .quote-item .quote-item-info .quote-author,
.ts-testimonial-static .quote-item .quote-item-info .quote-author {
  color: #333f48;
  font-size: 20px;
  font-weight: 700
}

.help-box .quote-item .quote-item-info .quote-subtext,
.ts-testimonial-static .quote-item .quote-item-info .quote-subtext {
  color: #97999b;
  font-size: 14px
}

.help-box .quote-classic,
.ts-testimonial-static .quote-classic {
  border: 1px solid #EAEAEA;
  padding-bottom: 25px
}

.help h2 {
  color: #fff;
  font-size: 24px
}

.help .btn-primary {
  background: #fff;
  color: #00F
}

.brochure {
  border: 2px solid #EAEAEA;
  font-size: 24px;
  text-align: center;
  padding: 25px
}

.brochure i {
  padding-left: 20px
}

.quote-classic .quote-text {
  margin: 40px 0 10px;
  display: inline-block;
  padding: 50px 40px 30px 45px;
  color: #97999b;
  background: none;
  position: relative;
  font-size: 16px
}

.quote-footer-classic .quote-author {
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 2px;
  font-weight: 600;
  display: block;
  color: #1f1c1c
}

/* .career-bg-1{background:url(../images/career/career_bg1.jpg)} */
/* .career-bg-2{background:url(../images/career/career_bg2.jpg)} */
/* .career-bg-3{background:url(../images/career/career_bg3.jpg)} */
/* .career-bg-4{background:url(../images/career/career_bg4.jpg)} */
/* .career-bg-5{background:url(../images/career/career_bg1.jpg)} */
.carrer-gallery-layout:after {
  clear: both;
  display: block;
  content: ""
}

.career-layout {
  background-size: cover;
  background-position: center;
  width: 31.5%;
  height: 320px;
  float: left
}

.career-layout.career-layout-double {
  width: 66.6666%
}

.career-layout.career-layout-small {
  height: 155px
}

.howto h3 {
  color: #fff
}

.howto p {
  color: #fff
}

.career-body {
  padding-top: 0
}

.job-box {
  background: #f7f7f7;
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 20px 30px;
  margin-bottom: 55px;
  margin-top: 55px;
  border-radius: 3px
}

.job-box .job-info {
  display: table-cell;
  line-height: 26px;
  padding: 0 10px;
  font-size: 15px
}

.job-box .job-info .icon {
  float: left;
  width: 25px;
  margin-top: 5px
}

.job-box .job-info .desc {
  overflow: hidden;
  font-weight: 500
}

.job-box .job-info .desc strong {
  display: block;
  color: #fa4616;
  margin-top: 5px
}

.skill-title {
  position: relative;
  margin-bottom: 50px
}

.skill-title :after {
  position: absolute;
  left: 0;
  bottom: -20px;
  content: "";
  background: #fa4616;
  width: 40px;
  height: 2px
}

.pricing {
  padding-bottom: 80px
}

.price-icon-wrapper {
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px
}

.plan {
  border: 1px solid #eaeaea;
  border-radius: 4px
}

.plan-hover {
  position: relative
}

.plan-hover:before {
  content: '';
  left: 0;
  top: 0;
  width: 0;
  transition: .5s ease;
  height: 100%;
  background: #fa4616;
  position: absolute
}

.plan ul {
  background: #fff;
  padding: 0;
  margin-bottom: 0
}

.plan li {
  padding: 8px 30px;
  list-style: none
}

.plan .btn {
  margin: 25px 0 40px
}

.plan.featured {
  background: #fa4616;
  color: #fff;
  padding: 40px 0;
  margin: -40px 0
}

.plan.featured ul {
  background: none
}

.plan.featured .plan-name {
  color: #fff
}

.plan.featured .plan-price {
  color: #fff
}

.plan.featured .btn {
  background: #fff;
  color: #fa4616;
  -webkit-transition: all .5s;
  transition: all .5s
}

.plan.featured .btn:hover {
  background: #1c121d;
  color: #fff
}

.plan.plan-last {
  margin-bottom: 0
}

.plan-name {
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding: 20px 0;
  color: #333f48;
  margin-bottom: 0
}

.plan-name small {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: normal
}

.plan-price {
  font-size: 48px;
  padding: 0;
  position: relative;
  color: #333f48;
  margin: 0 0 25px
}

.plan-price .currency {
  top: -.9em;
  font-size: 50%;
  left: -.01em;
  font-weight: 700
}

.plan-price sub {
  font-size: 20px;
  left: -.6em;
  position: relative
}

.ts-price-box {
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: -80px
}

.pricing-boxed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.pricing-boxed:after {
  content: "";
  clear: both;
  display: block
}

.single-price-box {
  width: 33.3333%;
  text-align: center
}

.single-price-box .pricing-header {
  min-height: 145px;
  padding: 10px 0;
  margin-bottom: 70px;
  background: #000;
  position: relative;
  z-index: 3
}

.single-price-box .pricing-header h3,
.single-price-box .pricing-header h2 {
  color: #fff
}

.plan-hover:hover:before {
  width: 100%
}

.plan:hover .plan-name,
.plan:hover .plan-price,
.plan:hover li {
  color: #fff;
  position: relative
}

.plan:hover a {
  background: #fff;
  color: #fa4616;
  position: relative
}

.plan:hover a:hover {
  background: #222;
  color: #fff
}

@media screen and (min-width: 992px) {
  .single-price-box .pricing-header.border-left {
    border-top-left-radius: 5px
  }

  .single-price-box .pricing-header.border-right {
    border-top-right-radius: 5px
  }
}

.single-price-box.featured {
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  background: #fff;
  margin-top: -30px
}

.single-price-box.featured .pricing-header {
  margin-bottom: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #FC6B2A
}

.single-price-box.featured .btn-primary {
  background: #FC6B2A;
  -webkit-transition: all .3s;
  transition: all .3s
}

.single-price-box.featured .btn-primary:hover {
  background: #000
}

.single-price-box .pricing-plan .list-unstyled li {
  padding-bottom: 12px
}

.single-price-box .pricing-plan .list-unstyled li:last-child {
  padding-bottom: 35px
}

.single-price-box .pricing-plan {
  padding-bottom: 40px;
  background: #fff
}

.card {
  border: none
}

.card-header {
  background: none !important;
  color: #97999b;
  padding: 20px 0 21px
}

.card-header i {
  background: #fa4616;
  color: #fff;
  border-radius: 5px;
  font-size: 20px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  margin-right: 15px
}

.card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none
}

.btn-link {
  color: #97999b;
  padding: 0
}

.btn-link:hover {
  text-decoration: none
}

.btn:focus {
  text-decoration: none !important
}

h5.mb-0 {
  margin-left: 0
}

h5.mb-0 button {
  position: relative;
  padding-left: 55px
}

h5.mb-0 button.collapsed {
  color: #333
}

h5.mb-0 button:before {
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px
}

h5.mb-0 button:before,
h5.mb-0 button .collapsed:before {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  z-index: 0;
  font-size: 16px;
  left: 0;
  padding: 0 5px;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #2155CF
}

.card-header button p {
  margin-bottom: 0;
  font-weight: 500;
  color: #97999b
}

h5.mb-0 button p:hover {
  color: #2155CF
}

h5.mb-0 button:before {
  content: "\f068"
}

h5.mb-0 button.collapsed:before {
  content: "\f067"
}

.card-header .collapsed .show {
  border-bottom: none
}

.quote-item .quote-text:before {
  content: "\e937";
  font-family: 'iconfont';
  position: absolute;
  font-size: 35px;
  left: 105px;
  color: #fa4616;
  background: none;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.page-slider.small-bg .item {
  min-height: 330px
}

.page-slider.small-bg .box-slider-content {
  left: 20%
}

.page-slider.small-bg .box-slider-text {
  background: #fff;
  padding: 5px 20px
}

.page-slider.small-bg .box-slide-title {
  font-size: 28px;
  color: #fff;
  font-weight: 900
}

.page-quote-form {
  background: #f2f2f2;
  padding: 30px 50px 50px
}

.get-a-quote-img {
  margin-bottom: -190px
}

.ts-pricing-box {
  margin: 20px 0;
  padding: 0;
  text-align: center;
  background: #f9f9f9
}

.ts-pricing-header {
  background: #252525;
  color: #fff;
  position: relative;
  padding: 30px 20px
}

.ts-pricing-name {
  font-size: 18px;
  line-height: normal;
  margin: 0 0 5px;
  color: #fff
}

.ts-pricing-price {
  font-size: 44px;
  color: #fff;
  margin: 15px 0 0;
  display: inline-block
}

.ts-pricing-price>small {
  font-size: 16px;
  line-height: 16px;
  display: block;
  margin-top: 15px;
  color: #fff
}

.ts-pricing-features {
  padding: 15px 0
}

.ts-pricing-features>ul {
  padding: 0 20px
}

.ts-pricing-features>ul>li {
  padding: 20px 0;
  border-top: 1px dotted #e5e5e5
}

.ts-pricing-features>ul>li:first-child {
  border-top: 0
}

.plan-action {
  padding-bottom: 40px
}

.ts-pricing-box.ts-pricing-featured .ts-pricing-header {
  background: #ff9700
}

.map {
  height: 700px;
  position: relative
}

.map.map-height {
  height: 450px
}

.contact-info-box {
  margin-top: 20px
}

.contact-info-box i {
  float: left;
  font-size: 24px;
  color: #fa4616
}

.contact-info-box-content {
  padding-left: 40px
}

.contact-info-box-content h4 {
  font-size: 16px;
  margin-top: 0;
  line-height: normal;
  font-weight: 700
}

.contact-info-box-content p {
  margin-bottom: 0
}

label {
  font-weight: 800
}

.box-border {
  border: 1px solid #eaeaea;
  padding: 20px 30px
}

.ts-contact-info .ts-contact-icon {
  float: left;
  font-size: 44px;
  color: #fa4616
}

.ts-contact-info .ts-contact-content {
  margin-left: 70px
}

.ts-contact-info .ts-contact-content p {
  margin: 0
}

.ts-contact-info .ts-contact-title {
  font-size: 20px;
  margin: 0
}

/* .contact-box.info-box{background:url(../images/news/news1.jpg);background-size:cover;background-position:center;position:relative;z-index:2} */
.contact-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: #fff;
  -webkit-box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1)
}

.contact-box {
  min-height: 450px;
  padding: 40px
}

.contact-box.form-box {
  width: 60%
}

.contact-box.info-box {
  padding-left: 40px;
  padding-top: 40px;
  width: 40%
}

.contact-box.info-box:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(15, 39, 101, 0.9);
  z-index: -1
}

.contact-box.info-box.form-box {
  padding: 40px
}

.contact-box.info-box .ts-contact-content h3,
.contact-box.info-box .ts-contact-content p {
  color: #fff
}

.contact-box.info-box .ts-contact-content .ts-contact-info i {
  color: #fff;
  font-size: 40px
}

.contact-info-right h3 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 30px
}

.contact-info-right .ts-contact-content {
  padding-bottom: 30px
}

.contact-info-right .ts-contact-content h3 {
  font-size: 20px
}

.contact-info-right .ts-contact-content p {
  font-size: 14px
}

.contact-info-right .ts-contact-info.last .ts-contact-content {
  padding-bottom: 15px
}

.contact-info-right .ts-contact-info i {
  color: #fff;
  font-size: 40px
}

.contact-info-social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-left: -40px
}

.contact-info-social-links li {
  display: inline-block
}

.contact-info-social-links li a {
  display: block;
  color: #fff;
  padding: 10px
}

.ts-form.form-boxed {
  margin-bottom: -200px
}

.post {
  margin: 0 0 50px
}

.post.last {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0
}

.post-body {
  padding: 25px 40px 40px 25px;
  border: 0;
  border-top: 0;
  background: #f7f9fd
}

.post-meta-left {
  width: 15%;
  margin-top: -20px
}

.post-meta-left span {
  display: inline-block;
  font-weight: 600;
  font-size: 14px
}

.post-content-right {
  width: 80%;
  float: right
}

.entry-header .entry-title {
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 15px
}

.entry-header .entry-title a {
  color: #333f48
}

.entry-header .entry-title a:hover {
  color: #fa4616
}

.post-media.post-video {
  max-width: 100%;
  position: relative;
  overflow: hidden
}

.post-media.post-video:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5)
}

.video-icon {
  text-align: center;
  color: #fff;
  display: block;
  top: 50%;
  margin-top: -50px;
  font-size: 78px;
  z-index: 1999;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  line-height: 65px;
  display: inline-block;
  border-radius: 100%;
  -webkit-transition: -webkit-transform .8s ease-in-out;
  transition: transform .8s ease-in-out
}

.video-icon i {
  -webkit-transition: 400ms;
  transition: 400ms;
  transition: 400ms
}

.video-icon i:hover {
  color: #fa4616
}

.post-meta-date {
  background: #fa4616;
  color: #fff;
  width: 65px;
  height: 65px;
  line-height: 26px;
  padding: 10px 0;
  position: relative;
  top: -40px
}

.post-meta-date span {
  font-size: 30px;
  font-weight: 600;
  display: block
}

.post-quote-wrapper {
  position: relative;
  color: #fff;
  background: #333f48;
  padding: 50px
}

.post-quote-content:before {
  padding: 28px 0 22px;
  content: "\e937";
  font-family: 'iconfont';
  color: #fff;
  font-size: 44px
}

.post-quote-content .entry-title {
  margin-top: 20px
}

.post-quote-content .entry-title a {
  color: #fff
}

.post-quote-content .meta-author {
  color: #ccc;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  margin-bottom: 15px
}

.post-quote-content .meta-author:before {
  content: '';
  position: absolute;
  top: 14px;
  height: 2px;
  width: 20px;
  background-color: #fa4616;
  margin-left: -30px
}

.post-quote-content .post-meta {
  color: #fff
}

.post-quote-content .post-meta a {
  color: #fff
}

.post-author {
  position: relative
}

.post-author:after {
  content: '';
  position: absolute;
  border-bottom: 1px solid #dedede;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 75px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transform: translateX(-50%)
}

.post-author .avatar {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 100%;
  margin-bottom: 5px
}

.post-meta-left span.post-author {
  display: block;
  padding-bottom: 5px;
  margin-bottom: 5px
}

.post-author a {
  display: block
}

.post-comment i {
  position: relative;
  bottom: -3px;
  margin-right: 5px
}

.post-meta {
  padding-bottom: 15px;
  color: #97999b;
  font-size: 14px
}

.post-meta a {
  color: #97999b;
  position: relative;
  z-index: 1
}

.post-meta-left a {
  color: #97999b;
  position: relative;
  z-index: 1
}

.post-meta a:hover,
.post-meta-left a:hover {
  color: #fa4616
}

.post-meta span {
  margin-right: 10px;
  padding-right: 10px;
  line-height: 12px;
  display: inline-block
}

.post-meta i {
  padding-right: 0;
  margin-right: 6px;
  font-weight: 500
}

.post-footer .btn.btn-primary {
  margin-top: 10px;
  padding: 10px 30px
}

.btn-play {
  background: #fff;
  color: #505cfd;
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  text-align: center;
  line-height: 60px;
  font-size: 22px;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  -webkit-transition-property: color, background, border-color;
  transition-property: color, background, border-color;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  margin-top: 0;
  margin-left: -40px
}

.btn-anim .btn-play:after,
.btn-anim .btn-play:before {
  content: '';
  border: 1px solid;
  border-color: inherit;
  width: 150%;
  height: 150%;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  left: -25%;
  top: -25%;
  opacity: 1;
  -webkit-animation: 1s videomodule-anim linear infinite;
  animation: 1s videomodule-anim linear infinite
}

.btn-anim .btn-play:before {
  -webkit-animation-delay: .5s;
  animation-delay: .5s
}

@-webkit-keyframes videomodule-anim {
  0% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68)
  }

  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0
  }
}

@keyframes videomodule-anim {
  0% {
    -webkit-transform: scale(0.68);
    transform: scale(0.68)
  }

  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0
  }
}

.home-about-area {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 100px 0;
  border-top: 1px solid #f1f1f1;
  position: relative
}

/* .home-about-area:before{content:'';width:100%;height:100%;left:0;top:0;background:url(../images/pattern/bg_pattern2.png);position:absolute} */
.bo-site-heading-primary {
  margin-bottom: 15px
}

.bo-site-heading-primary h4 {
  color: #0c90ce;
  font-family: 'Barlow', sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: lowercase
}

.bo-site-heading-primary h2 {
  font-family: 'Barlow', sans-serif;
  letter-spacing: -1px
}

.home-about-right {
  padding: 0;
  margin: 0
}

.home-about-right img {
  max-width: 100%
}

#services {
  background-color: #f5f5f5;
  padding: 60px 0
}

.service-single-item {
  padding: 55px 20px 40px;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-transition: .3s;
  transition: .3s
}

.service-single-item:hover {
  background-image: -moz-linear-gradient(0deg, #143481 0%, #505cfd 100%);
  background-image: -webkit-linear-gradient(0deg, #143481 0%, #505cfd 100%);
  background-image: -ms-linear-gradient(0deg, #143481 0%, #505cfd 100%);
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.04);
  transform: scale(1.04)
}

.service-single-item:hover h4,
.service-single-item:hover p {
  color: #fff
}

.service-single-item i {
  background-image: -moz-linear-gradient(0deg, #143481 0%, #505cfd 100%);
  background-image: -webkit-linear-gradient(0deg, #143481 0%, #505cfd 100%);
  background-image: -ms-linear-gradient(0deg, #143481 0%, #505cfd 100%);
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  padding: 20px 0;
  text-align: center;
  line-height: 38px;
  display: inline-block
}

.service-single-item:hover i {
  background: #fff;
  color: #8675ff;
  border-radius: 50px;
  padding: 20px 18px;
  width: 80px;
  height: 80px;
  font-size: 30px
}

.service-single-item h4 {
  font-weight: 600;
  margin-bottom: 15px
}

.about-content {
  padding: 0;
  margin: 0
}

.about-content h3 {
  font-weight: 'Barlow', sans-serif;
  font-weight: 500
}

.about-content h2 {
  font-weight: 'Barlow', sans-serif;
  font-weight: 600;
  margin-bottom: 30px
}

.about-content p {
  font-weight: 'Barlow', sans-serif;
  font-weight: 300;
  margin-bottom: 50px
}

.welcome-area {
  background: #fff
}

.well-services {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  z-index: 1
}

.main-services {
  display: block;
  overflow: hidden;
  padding: 20px;
  position: relative;
  z-index: 1
}

.well-icon {
  width: 60px;
  float: left;
  height: 100px;
  font-size: 40px;
  color: #fa4616
}

.services-img {
  overflow: hidden;
  position: relative
}

.services-img::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 70, 22, 0.6) none repeat scroll 0 0;
  z-index: 1;
  opacity: 0
}

.services-img img {
  max-width: 100%;
  height: auto;
  transform: scale(1.1);
  transition: .4s
}

.service-content {
  padding-left: 60px
}

.service-content h4 {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 0 0 10px;
  text-transform: uppercase
}

.service-btn {
  font-weight: 700;
  text-transform: uppercase;
  color: #444;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  z-index: 2
}

.main-services::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 70, 22, 0.9) none repeat scroll 0 0;
  z-index: -1;
  opacity: 0;
  transition: .4s
}

.well-services:hover .service-content h4,
.well-services:hover .service-content p,
.well-services:hover .service-content .service-btn {
  color: #fff
}

.well-services:hover .well-icon {
  color: #fff
}

.well-services:hover .main-services::after {
  opacity: 1
}

.well-services:hover .services-img img {
  transform: scale(1);
  transition: .4s
}

.well-services:hover .services-img::after {
  opacity: 1
}

.well-services:hover .service-btn:hover {
  color: #fa4616
}

.choosing {
  padding: 6rem 0
}

.choosing .choos img {
  width: 360px
}

.choosing .why-do {
  padding: 1rem 0;
  background: #3b526b;
  color: #fff
}

.choosing .why-do p {
  font-weight: 300;
  padding: 0 1rem;
  margin: 0
}

.choosing .why-do a {
  text-decoration: none;
  color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease
}

.choosing .why-do a:hover {
  color: #18ba60
}

.hovblue figure {
  background: #18ba60;
  margin: 0
}

.hovblue figure:hover img {
  opacity: .5
}

@media screen and (max-width: 767px) {
  .choosing .cons {
    margin-bottom: 2rem
  }
}

.offer-details a:hover {
  text-decoration: none
}

.offer-details .readmore:before {
  content: '\f101';
  font-family: 'Font Awesome 5 Free';
  color: #fff;
  right: -20px;
  position: absolute
}

.offer-item .inner:hover h3 a {
  color: #f8ba00
}

.offer-item .inner:nth-child(3n+1) {
  clear: both
}

.offer-details .btn.rdmorebtn:hover {
  background: #000;
  -webkit-transition: all .4s ease-in-out 0;
  transition: all .4s ease-in-out 0
}

.offer-inner {
  position: relative;
  overflow: hidden;
  margin-bottom: 0
}

.offer-details {
  position: absolute;
  top: 178px;
  height: 100%;
  padding: 20px;
  color: #fff;
  background-color: rgba(250, 70, 22, 0.6);
  -webkit-transition: all .4s ease 0;
  transition: all .4s ease 0
}

.offer-details h3 a span {
  float: right
}

.offer-inner:hover .offer-details {
  background-color: rgba(33, 84, 270, 0.6);
  color: #fff;
  top: 0
}

.offer-inner:hover .offer-details h3 a {
  color: #fff
}

.offer-details h3 a {
  color: #fff;
  font-family: 'Barlow', sans-serif;
  font-size: 20px;
  font-weight: 500
}

.offer-details h3 {
  margin-bottom: 20px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease
}

.offer-img {
  width: 360px;
  width: 100%;
  height: 247px
}

.readmore {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-top: 1rem;
  position: relative;
  text-transform: capitalize;
  top: -8px;
  -webkit-transition: all .5s ease;
  transition: all .5s ease
}

.offer-details .readmore:hover {
  color: #111
}

@media screen and (max-width: 767px) {
  .offer-details {
    top: 181px
  }
}

@media screen and (max-width: 991px) {
  .offer-details {
    top: 181px;
    padding-left: 10px;
    padding-right: 10px
  }
}

.tg-bglight {
  margin: 0;
  padding: 0;
  background: #f1f1f1
}

.ts-getshortcode .tg-shortcodeimg {
  width: auto;
  background: none;
  padding: 35px 35px 0
}

.ts-getshortcode .tg-shortcodeimg img {
  opacity: 1;
  background: none
}

.ts-getshortcode .tg-shortcodetext {
  width: auto;
  float: none;
  padding: 85px 0;
  overflow: hidden
}

.ts-getshortcode .tg-shortcodetext h2 {
  margin: 0 0 25px;
  color: #424242;
  font-size: 30px;
  line-height: 30px
}

.tg-contactnumbers {
  color: #424242;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle;
  padding: 12px 0 12px 30px
}

.tg-shortcodeimg {
  margin: 0;
  width: 100%;
  float: left;
  background: #000
}

.tg-shortcodeimg img {
  z-index: 2;
  width: 100%;
  opacity: .5;
  display: block;
  position: relative
}

.offer_area_full {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 628px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  z-index: 1
}

.offer_area p {
  color: hsl(0, 0%, 13%);
  font-size: 14px;
  font-weight: 300;
  line-height: 27px;
  padding: 16px 0
}

.offer_area h1 {
  font-family: 'Barlow', sans-serif;
  letter-spacing: -2px;
  color: hsl(0, 0%, 13%);
  font-size: 37px;
  font-weight: 700;
  line-height: 50px;
  text-transform: none
}

.offer_area span {
  color: #fa4616
}

.about-register {
  margin: 0
}

.bx-shadow {
  -webkit-box-shadow: 0 10px 18px 0 rgba(166, 166, 166, 1);
  -moz-box-shadow: 0 10px 18px 0 rgba(166, 166, 166, 1);
  box-shadow: 0 10px 18px 0 rgba(166, 166, 166, 1)
}

/* .home-services{background:url(../images/slider/bg9.jpg);background-position:center;background-size:cover;position:relative} */
.home-services:before {
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute
}

.home-services .icon-box {
  margin-bottom: 40px
}

.home-services .icon-box i {
  font-size: 40px
}

.home-services .icon-box h4 {
  color: #fff;
  font-size: 20px
}

.home-services .icon-box p {
  font-size: 14px;
  font-weight: 300;
  color: #d3d3d3
}

.paging {
  margin-bottom: -5px
}

.pagination {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0
}

.pagination>li>a,
.pagination>li>span {
  border-radius: 100% !important;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  line-height: 28px;
  color: #7c7c7c;
  border: 1px solid #f1f1f1;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  -webkit-transition: all .3s;
  transition: all .3s
}

.pagination>.active>a {
  color: #fff;
  background: #fa4616;
  border: 1px solid transparent
}

.pagination>.active>a:hover {
  color: #fff;
  background: #fa4616;
  border: 1px solid transparent
}

.pagination>li>a:hover {
  color: #fff;
  background: #fa4616;
  border: 1px solid transparent
}

.post-single {
  position: relative
}

.post-single .post-body {
  border: 1px solid #f1f1f1;
  background: none;
  padding: 40px
}

.post-single .post-meta-date {
  position: absolute;
  top: 30px;
  left: 35px;
  display: block;
  text-align: center
}

.post-single .post-author .avatar {
  width: 30px;
  height: 30px
}

.post-single .post-author a {
  display: inline-block;
  margin-left: 6px
}

.post-single .post-author:after {
  border: 0
}

.post-single .entry-header .entry-title {
  font-size: 36px
}

.post-single .entry-content p {
  margin-bottom: 30px
}

.post-single .entry-content h3 {
  margin: 30px 0
}

.post-single .post-footer {
  border-top: 1px solid #f1f1f1;
  margin: 40px 0 0;
  padding-top: 40px
}

.tags-area {
  margin: 20px 0
}

.post-tags strong {
  margin-right: 5px
}

.post-tags a {
  border: 1px solid #f1f1f1;
  color: #97999b;
  display: inline-block;
  font-size: 14px;
  padding: 3px 15px;
  margin-left: 3px;
  border-radius: 25px
}

.post-tags a:hover {
  background: #fa4616;
  color: #fff;
  border: 1px solid transparent
}

.post-social-icons>li {
  display: inline-block
}

.post-social-icons a {
  margin-left: 10px;
  font-size: 16px;
  color: #333f48;
  text-align: center
}

.post-social-icons a:hover {
  color: #fa4616
}

.post-navigation span:hover,
.post-navigation h3:hover {
  color: #fa4616
}

.post-navigation .post-previous,
.post-navigation .post-next {
  padding: 0 40px;
  width: 50%;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  display: table-cell;
  position: relative;
  vertical-align: middle
}

.post-navigation i {
  margin: 0 5px;
  color: #fa4616
}

.post-navigation span {
  font-size: 14px;
  color: #97999b;
  margin-bottom: 10px
}

.post-navigation .post-previous {
  text-align: left;
  float: left;
  border-left: 0 none;
  border-right: 0 none;
  padding: 0 40px 0 0
}

.post-navigation .post-next {
  text-align: right;
  float: left;
  border-right: 0 none;
  padding: 0 0 0 40px
}

.post-navigation h3 {
  font-size: 20px;
  line-height: 26px;
  margin: 8px 0 0
}

.author-box {
  padding: 40px;
  margin: 40px 0
}

.author-img img {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  border-radius: 100%
}

.author-info h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600
}

.author-info h3 span {
  font-size: 12px;
  color: #999;
  border-left: 1px solid #AFAFAF;
  padding-left: 10px;
  margin-left: 10px;
  font-weight: 500
}

.author-info p {
  padding-left: 130px
}

.author-url a {
  font-size: 14px;
  color: #97999b
}

.comments-area {
  margin: 40px 0
}

.comments-list .comment-content {
  margin: 15px 0
}

.comments-list .comment-reply {
  color: #333f48;
  font-weight: 400;
  font-size: 14px
}

.comments-list .comment-reply i {
  margin-right: 5px
}

.comments-list .comment-reply:hover {
  color: #fa4616
}

.comments-counter {
  font-size: 18px
}

.comments-counter a {
  color: #323232
}

.comments-list {
  list-style: none;
  margin: 0;
  padding: 20px 0
}

.comments-list .comment {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 20px;
  margin-bottom: 30px
}

.comments-list .comment.last {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0
}

.comments-list img.comment-avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 30px
}

.comments-list .comment-body {
  margin-left: 110px
}

.comments-list .comment-author {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
  font-size: 18px;
  color: #333f48
}

.comments-list .comment-date {
  color: #333f48;
  font-size: 14px;
  display: block;
  margin-top: -5px
}

.comments-reply {
  list-style: none;
  margin: 0 0 0 70px
}

.comments-form {
  margin-bottom: 0
}

.comments-form .title-normal {
  margin-bottom: 20px
}

.comments-form .btn.btn-primary {
  margin-top: 20px
}

.our_service_area {
  background: #f4f4f4;
  padding: 40px 0
}

.our_service_area.our_white_service {
  background: #fff
}

.our_service_area.our_white_service .section_title {
  padding-bottom: 17px
}

.service_inner {
  margin-bottom: -30px;
  overflow: hidden
}

.service_item {
  padding: 34px 15px 34px 24px;
  background: #fff;
  margin-bottom: 30px;
  position: relative
}

.service_item:before {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fa4616;
  position: absolute;
  transition: .3s ease
}

.service_item .media .media-left {
  padding-right: 20px;
  position: relative
}

.service_item .media .media-left img+img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

.service_item .media .media-body h4 {
  font-size: 16px;
  font-family: 'Barlow', sans-serif;
  color: #242424;
  letter-spacing: .32px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 0
}

.service_item .media .media-body p {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Barlow', sans-serif;
  letter-spacing: .32px;
  color: #898989;
  font-weight: 400;
  padding-bottom: 0
}

.service_item .media .media-body .more_link {
  font-size: 14px
}

.service_item:hover .media-body p,
.service_item:hover .media-body h4,
.service_item:hover .media-body .more_link {
  color: #fff !important;
  position: relative
}

.service_item:hover .media .media-left img {
  opacity: 0
}

.service_item:hover .media .media-left img+img {
  opacity: 1
}

.service_item:hover .media .media-body h4 {
  color: #fff
}

.service_item:hover .media .media-body p {
  color: #99a7b6
}

.more_link {
  font-family: 'Barlow', sans-serif;
  font-weight: 700;
  letter-spacing: .384px;
  color: #fa4616;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: all 400ms linear 0;
  -o-transition: all 400ms linear 0;
  transition: all 400ms linear 0
}

.more_link:hover {
  padding-left: 10px;
  text-decoration: underline
}

.manager_area {
  padding-top: 30px;
  position: relative
}

/* .manager_area:before{content:'';width:100%;height:100%;top:0;left:0;background:url(../images/pattern/bg_pattern1.png);position:absolute} */
.manager_area .our_about_left_content {
  padding-top: 90px;
  padding-left: 40px;
  padding-right: 0
}

.manager_area .our_about_left_content h2 {
  font-family: 'Barlow', sans-serif
}

.manager_area .our_about_left_content h3 {
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  margin-top: 20px
}

.manager_area .our_about_left_content .section_title {
  padding-bottom: 20px
}

.manager_area .our_about_left_content img {
  padding-top: 10px
}

.manager_image {
  margin-right: -50px
}

.manager_image img {
  max-width: 100%;
  margin-bottom: -76px
}

.sidebar .widget-title {
  font-size: 24px;
  position: relative;
  margin: 0 0 30px;
  padding-left: 15px;
  border-left: 3px solid #fa4616
}

.sidebar .widget {
  margin-bottom: 20px;
  border: 1px solid #f1f1f1;
  padding: 20px
}

.sidebar .widget.widget-ad {
  padding: 0;
  border: none
}

.sidebar-left .widget {
  margin-right: 20px
}

.sidebar .widget.box {
  padding: 25px
}

.widget.box.solid {
  background: #f2f2f2
}

.widget.box.red {
  background: #ec483b;
  color: #fff
}

.widget.box.red .widget-title {
  color: #fff
}

.widget ul li {
  line-height: 30px
}

.sidebar .widget ul li a:hover {
  color: #fa4616
}

.sidebar .widget ul li i {
  margin-right: 5px;
  font-weight: 500
}

.sidebar .btn {
  font-weight: 800;
  margin-top: 15px
}

.widget.widget-search {
  background: none;
  position: relative;
  padding: 0
}

.input-group-btn {
  background: #fa4616;
  color: #fff
}

.widget-search .form-control {
  border: 0 !important;
  font-style: italic;
  background-color: none
}

.widget-search .form-control:focus {
  border: 0 !important;
  font-style: italic;
  background-color: none
}

.widget-search i {
  color: #fff;
  background: #fa4616;
  font-size: 18px;
  position: relative;
  top: 0;
  padding: 15px
}

.widget-nav-tabs {
  margin: 0;
  padding: 0;
  list-style: none
}

.widget-nav-tabs li {
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px dashed #EAEAEA;
  margin-bottom: 10px
}

.widget-nav-tabs li a {
  font-size: 16px;
  color: #97999b;
  font-family: 'Barlow', sans-serif;
  -webkit-transition: all .3s;
  transition: all .3s
}

.widget-nav-tabs li span {
  float: right
}

.widget-nav-tabs li:after {
  display: block;
  content: "";
  clear: both
}

.widget-nav-tabs li:last-child {
  border: none;
  padding: 0;
  margin: 0
}

.instagram-photo li img {
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 3px
}

.widget.recent-posts .widget-title {
  margin-bottom: 35px
}

.widget.recent-posts ul li {
  border-bottom: 1px dashed #f1f1f1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  line-height: normal
}

.widget.recent-posts ul li:last-child {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0
}

.widget.recent-posts .media-left img {
  width: 70px
}

.widget.recent-posts .entry-title {
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0 0
}

.widget.recent-posts .entry-title a {
  color: #303030;
  display: inline-block;
  padding: 5px 0
}

.widget.recent-posts .entry-title a:hover {
  color: #fa4616
}

.widget.recent-posts .entry-title small {
  color: #97999b;
  display: block;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Barlow', sans-serif
}

.widget.recent-posts .post-date {
  font-weight: 500;
  color: #97999b;
  font-size: 14px
}

.widget.recent-posts .post-date a {
  color: #97999b
}

.widget-ad p {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  line-height: 40px
}

.widget-ad .btn {
  padding: 18px 60px;
  font-size: 16px;
  font-weight: 800;
  background: #fff;
  color: #fa4616
}

.widget-ad .btn:hover {
  background: #97999b;
  color: #fff
}

/* .widget-ad-bg{position:relative;z-index:2;background-image:url(../images/services/service5.jpg);background-size:cover;background-position:right;padding:145px 0;text-align:center} */
.widget-ad-bg.bg-overlay:after {
  z-index: -1
}

.widget-ad-bg.overlay-color:after {
  background: rgba(43, 84, 182, 0.85);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 84, 182, 0.85)), to(rgba(43, 84, 182, 0.85)));
  background: linear-gradient(to bottom, rgba(43, 84, 182, 0.85) 0%, rgba(43, 84, 182, 0.85) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(#2b54b6,.85)', endColorstr='rgba(#2b54b6,.85)', GradientType=0);
  z-index: -1
}

.widget-tags ul>li {
  float: left;
  margin: 3px
}

.sidebar .widget-tags ul>li a {
  border: 1px solid #f1f1f1;
  color: #97999b;
  display: inline-block;
  font-size: 14px;
  padding: 5px 20px;
  margin: 0 3px 3px 0;
  border-radius: 25px;
  -webkit-transition: all .3s ease 0;
  transition: all .3s ease 0;
  transition: all .3s ease 0
}

.sidebar .widget-tags ul>li a:hover {
  background: #fa4616;
  color: #fff;
  border: 1px solid transparent
}

.not-found {
  padding-top: 0
}

.error-page .error-code {
  display: block;
  font-size: 150px;
  line-height: 150px;
  color: #333;
  margin-bottom: 20px;
  text-shadow: 5px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 50px 0
}

.error-page .error-body .btn {
  margin-top: 30px;
  font-weight: 700
}

.not-found p {
  font-size: 200px;
  line-height: 1.1;
  margin-bottom: 20px
}

.not-found p small {
  display: block
}

.alert-danger h4 {
  color: #dc3545;
  margin-top: 10px
}

.owl-prev,
.owl-next {
  top: 50%
}

.owl-prev {
  left: 0px
}

.owl-next {
  right: 0px
}

.slider-contenedor {
  width: 100%;
  overflow: hidden;
  position: relative
}

.slider-picture {
  display: block;
  max-width: 100%;
  height: 100%
}

.banner {
  height: 300px;
  background-size: cover;
  background-position: center;
}

.banner.quienes-somos {
  background-image: url('../images/banners/quienes_somos.jpg')
}

.banner.equipo {
  background-image: url('../images/banners/equipo.jpg')
}

.fondo-manuales {
  background-image: url('../images/fondo/manuales.jpg')
}

.fondo-reglamentos {
  background-image: url('../images/fondo/reglamentos.jpg')
}

.fondo-politicas {
  background-image: url('../images/fondo/politicas.jpg')
}

.fondo-hechos {
  background-image: url('../images/fondo/hechos.jpg')
}

.banner-principal {
  height: 550px;
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: "transform 2s";
}

.banner-principal.home {
  background-image: url('../images/banners/home.jpg');
}

.banner-principal.acerca {
  height: 400px;
  background-image: url('../images/banners/acerca.jpg');
}

.banner-principal.documentos {
  height: 400px;
  background-image: url('../images/banners/documentos.jpg');
}

.banner-principal.fondos {
  height: 400px;
  background-image: url('../images/banners/fondos.jpg');
}

.banner-principal.contacto {
  height: 400px;
  background-image: url('../images/banners/contacto.jpg');
}

.banner-principal.inicio_sesion {
  height: 400px;
  background-image: url('../images/banners/inicio_sesion.jpg');
}
